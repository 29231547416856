import { Alert, Paper, ThemeProvider, createTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import './ad.scss';
import { Link } from 'react-router-dom';
import { MenuBook } from '@mui/icons-material';

export default function Ad(props) {
  const [display, setDisplay] = useState(props.display);
  let isMobileUser = useMediaQuery({ query: '(max-width:1000px)' });

  const theme = createTheme({
    props: {
      MuiButton: {
        size: 'small',
        border: '1px solid',
      },
    },
  });

  const styles = {
    color: '#2b2b2b',
    background: '#f5e3cb',
    '&:hover': {
      backgroundColor: '#e1c8aa',
    },
  };

  const renderMobileAd = () => {
    if (display === true) {
      return (
        <React.Fragment>
          <div className="mobile-ad-container">
            <Paper className="mobile-ad-wrapper" elevation={5}>
              <div>
                <p>
                  <CloseIcon
                    style={{
                      color: '#e1c8aa',
                    }}
                    className="close-icon-mobile"
                    onClick={() => setDisplay(false)}
                  />
                </p>
              </div>
              <div>
                <center>
                  <Link
                    to={'film/the-whitewash'}
                    onClick={() => {
                      let selectedSpine = document.querySelector(
                        '.film-spine-flexbox-wrapper-active'
                      );
                      if (selectedSpine) {
                        selectedSpine.className = 'film-spine-flexbox-wrapper';
                      }
                      // if (isMobileUser) {
                      document.getElementById('main-content').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center',
                      });
                      // }
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Button fullWidth sx={styles}>
                        <span>
                          Get <em>The Whitewash</em>, the award-winning novel by
                          the co-creator of The Beige Index
                        </span>
                      </Button>
                    </ThemeProvider>
                  </Link>
                </center>
              </div>
              <div className={'right'}>
                <p>
                  <Link
                    to={'film/the-whitewash'}
                    onClick={() => {
                      let selectedSpine = document.querySelector(
                        '.film-spine-flexbox-wrapper-active'
                      );
                      if (selectedSpine) {
                        selectedSpine.className = 'film-spine-flexbox-wrapper';
                      }
                      if (isMobileUser) {
                        document.getElementById('shelf').scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'center',
                        });
                      }
                    }}
                  >
                    <MenuBook sx={{ color: '#e1c8aa' }} />
                  </Link>
                </p>
              </div>
            </Paper>
          </div>
        </React.Fragment>
      );
    }
  };

  return renderMobileAd();
}
