import React, { Component } from 'react';

import CastBox from './castbox';
import './cast.scss';

class CastContainer extends Component {
  state = {
    cast_color: {
      1: '#f5e3cb',
      2: '#e1c8aa',
      3: '#c6a07d',
      4: '#a27753',
      5: '#645246',
      1000: '#FF0000',
    },
  };
  xw;

  render() {
    const { cast } = this.props;
    return (
      <React.Fragment>
        <div className="cast-grid">
          {cast.map((cast, i) => {
            return <CastBox castMember={cast} key={`cast-${i}`} />;
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default CastContainer;
