export const oscarsData = [
  {
    imdb_id: 'tt1016150',
    title: 'All Quiet on the Western Front',
    directors: 'Edward Berger',
    release_year: 2022,
    beige_score: 12,
    beige_angle: 0,
    beige_index: 1,
    beige_angle_string: '0 111.63 111.37',
    cast_count: 55,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [1, 'Felix Kammerer'],
      [1, 'Albrecht Schuch'],
      [1, 'Aaron Hilmer'],
      [1, 'Moritz Klaus'],
      [1, 'Adrian Grünewald'],
      [1, 'Edin Hasanovic'],
      [1, 'Daniel Brühl'],
      [1, 'Thibault de Montalembert'],
      [1, 'Devid Striesow'],
      [1, 'Andreas Döhler'],
      [1, 'Sebastian Hülk'],
      [1, 'Luc Feit'],
      [1, 'Michael Wittenborn'],
      [1, 'Michael Stange'],
      [1, 'Sascha Nathan'],
      [1, 'Tobias Langhoff'],
      [1, 'Anton von Lucke'],
      [1, 'Michael Pitthan'],
      [1, 'Joe Weintraub'],
      [1, 'Charles Morillon'],
      [1, 'Gabriel Dufay'],
      [1, 'Dan Brown'],
      [1, 'Philipp Schenker'],
      [1, 'Cyril Dobrý'],
      [1, 'Sebastian Jacques'],
      [1, 'Gregory Gudgeon'],
      [1, 'Cyril Cechák'],
      [1, 'Jakob Schmidt'],
      [1, 'Felix von Bredow'],
      [1, 'Martin Dostál'],
      [1, 'Marek Simbersky'],
      [1, 'Jakob Diehl'],
      [1, 'Friedrich Berger'],
      [1, 'Anthony Paliotti'],
      [1, 'Radek Brodil'],
      [1, 'Jonathan Henault'],
      [1, 'Nico Ehrenteit'],
      [1, 'Hendrik Heutmann'],
      [1, 'Daniel Kamen'],
      [1, 'Tomás Capek'],
      [1, 'Markus Tomczyk'],
      [1, 'Emil Rothermel'],
      [1, 'Wolf Danny Homann'],
      [1, 'Michal Závodský'],
      [1, 'Nicolas Prokop'],
      [1, 'Jacob Zacharias Eckstein'],
      [1, 'Adam Mensdorff'],
      [1, 'Peter Sikorski'],
      [1, 'Alzbeta Malá'],
      [1, 'Andrea Zatloukalová'],
      [1, 'Thomas Zielinski'],
      [1, 'Tomás Weber'],
      [1, 'Samuel Neduha'],
      [1, 'Martin Nemec'],
      [1, 'Tomás Merkl'],
    ],
    synopsis: (
      <>
        <p>
          OUTRAGEOUS. WHAT IS THAT, BLACKFACE? IS THAT BLACKFACE? CANCEL THEM.
        </p>
        <p>
          Oh wait, it's just soot from battle, caked onto the face of a
          shell-shocked white boy? Our bad. Honest mistake.
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt1630029',
    title: 'Avatar: The Way of Water',
    directors: 'James Cameron',
    release_year: 2022,
    beige_score: 15.93,
    beige_angle: 53.35,
    beige_index: 2,
    beige_angle_string: '53.35 111.63 111.37',
    cast_count: 58,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'CCH Pounder'],
      [5, 'Robert Okumu'],
      [5, 'Keston John'],
      [5, 'Johnny Alexander'],
      [4, 'Zoe Saldana'],
      [4, 'Cliff Curtis'],
      [4, 'Shane Rangi'],
      [4, 'Ava Diakhaby'],
      [4, 'Isaac Te Reina'],
      [4, 'Bailey Bass'],
      [4, 'Dileep Rao'],
      [3, 'Jemaine Clement'],
      [3, 'Duane Evans Jr.'],
      [3, 'Sean Anthony Moran'],
      [3, 'Victor Lopez'],
      [3, 'Maria Walker'],
      [3, 'Cruz Moir'],
      [3, 'Devereau Chumrau'],

      [2, 'Trinity Jo-Li Bliss'],
      [2, 'Filip Geljo'],

      [2, 'Scarlett Fernandez'],

      [2, 'Alicia Vela-Bailey'],
      [2, 'Andrew Arrabito'],
      [2, 'Kim Do'],
      [2, 'Moana Ete'],
      [2, 'Nikita Tu-Bryant'],
      [1, 'Sam Worthington'],
      [1, 'Giovanni Ribisi'],
      [1, 'Matt Gerald'],
      [1, 'Jennifer Stafford'],
      [1, 'Kevin Dorman'],
      [1, 'Sigourney Weaver'],
      [1, 'JK Jr'],
      [1, 'Stephen Lang'],
      [1, 'Kate Winslet'],
      [1, 'Joel David Moore'],
      [1, 'Edie Falco'],
      [1, 'Brendan Cowell'],
      [1, 'Jamie Flatters'],
      [1, 'Britain Dalton'],
      [1, 'Jack Champion'],
      [1, 'Phil Brown'],
      [1, 'Jocelyn Christian'],
      [1, 'Joel Tobeck'],
      [1, 'Phil Peleton'],
      [1, 'Jamie Landau'],

      [1, 'Benjamin Hoetjes'],
      [1, 'Rick Lucas'],
      [1, 'Tanya Drewery'],
      [1, 'Anthony Ahern'],
      [1, 'Eric Farmer'],

      [1, 'Daniel Lough'],
      [1, 'Chloe Coleman'],
      [1, 'Jeremy Irwin'],

      [1, 'Kevin Henderson'],
      [1, 'Jake McLean'],
    ],
    synopsis: (
      <>
        <p>
          So the first <em>Avatar</em> was full of blue people—and don't worry,
          they're back. But don't think James hasn't been working hard to
          introduce diversity to this blockbuster franchise.
        </p>
        <p>
          A lot has changed in fourteen years, and that's why{' '}
          <em>Avatar: The Way of Water</em> not only has blue people, but teal
          people too. Never mind that the teal people are coded to be the Na'vi
          equivalent of Pacific Island descent—nor that the main teal person is
          played by known white person Kate Winslet.
        </p>
        <p>
          No, never mind any of that. Because we're less than a year out from
          Avatar 3 and boy howdy has James had some things to say about that
          upcoming masterpiece.
        </p>
        <p>
          From his interview with French outlet{' '}
          <a
            href="https://www.20minutes.fr/cinema/4013493-20221210-avatar-voie-eau-film-rien-acteurs-estime-james-cameron"
            target="_blank"
            rel="noreferrer"
          >
            20 Minutes
          </a>
          :
        </p>
        <p className="tab">
          To show cultures different from those I have already shown... the fire
          will be represented by the ‘Ash People.’ I want to reveal the Na’vi
          from another angle because, for the moment, I have only shown their
          good sides. In the early films, there are very negative human examples
          and very positive Na’vi examples. In Avatar 3, we’ll have the
          opposite.
        </p>
        <p>
          Here's our Beige Guarantee™: this whole 'Ash People' thing can only go
          well.
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt11813216',
    title: 'The Banshees of Inisherin',
    directors: 'Martin McDonagh',
    release_year: 2022,
    beige_score: 12,
    beige_angle: 0,
    beige_index: 1,
    beige_angle_string: '0 111.63 111.37',
    cast_count: 20,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [1, 'Colin Farrell'],
      [1, 'Brendan Gleeson'],
      [1, 'Kerry Condon'],
      [1, 'Barry Keoghan'],
      [1, 'Gary Lydon'],
      [1, 'Pat Shortt'],
      [1, 'Sheila Flitton'],
      [1, 'Bríd Ní Neachtain'],
      [1, 'Jon Kenny'],
      [1, 'Aaron Monaghan'],
      [1, 'David Pearse'],
      [1, 'John Carty'],
      [1, 'Oliver Farrelly'],
      [1, 'Lasaírfhiona Ní Chonaola'],
      [1, 'James Carty'],
      [1, 'Conor Connolly'],
      [1, 'Ryan Owens'],
    ],
    synopsis: (
      <>
        <p>
          Ever hear of the Black Irish? It's a term Irish folk made up for
          people with black hair and dark eyes, obviously to sneakily try to
          claim beige status.
        </p>
        <p>
          Colin Farrell's Black Irish. And by coincidence, so's one of The Beige
          Index's co-creators. We'll leave our lovely mugs right here and you
          can guess which one.
        </p>
        <img
          src="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/headshots/double.jpg"
          alt="The Beige Index – The Banshees of Inisherin"
          className="assessment-image"
        />
      </>
    ),
  },
  {
    imdb_id: 'tt3704428',
    title: 'Elvis',
    directors: 'Baz Luhrmann',
    release_year: 2022,
    beige_score: 19.65,
    beige_angle: 101.61,
    beige_index: 3,
    beige_angle_string: '101.61 111.63 111.37',
    cast_count: 20,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'Traneshia Chiles'],
      [5, 'Lauren McClinton'],
      [5, 'Kellee Halford'],

      [5, 'Jamiah Hudson'],

      [5, 'Alfreda McCrary'],
      [5, 'Angela McCrary'],
      [5, 'Beverly Ann McCrary'],
      [5, 'Keb Mo'],
      [5, 'Tamica Nicole'],
      [5, 'Marqo Patton'],
      [5, 'Robert Randolph'],
      [5, 'Calvin Settles II'],
      [5, 'Calvin V. Settles Sr.'],
      [5, 'Ira Wayne Settles Sr.'],
      [5, 'Odessa L. Settles'],
      [5, 'Sara Settles'],
      [5, 'Shirley M. Settles'],
      [5, 'Gary Clark Jr.'],
      [5, 'Yola'],
      [5, 'Jordan A. Holland'],
      [5, 'Lenesha Randolph'],
      [5, 'Alton Mason'],
      [5, 'Shonka Dukureh'],
      [5, 'Shannon Sanders'],
      [5, 'Torshia Suggs'],
      [5, 'Cameron Keith Walls'],
      [5, 'Andrea Baker'],
      [5, 'Kelvin Harrison Jr.'],
      [5, 'Christian Kisando'],
      [5, 'John Mukristayo'],
      [5, 'Miles Burton'],
      [5, 'Gad Banza'],
      [5, 'Aristene Kisando'],
      [5, 'Senayt Mebrahtu'],
      [5, 'Sharon Brooks'],
      [5, 'Charles Allen'],
      [5, 'Hilton Hyppolite Denis'],
      [4, 'Lakota Johnson'],
      [4, 'Cle Morgan'],
      [3, 'Chaydon Jay'],
      [3, 'Princess Mariama Andrews'],
      [3, 'Terepai Richmond'],
      [2, 'Vanesa Everett'],
      [1, 'Nicholas Bell'],
      [1, 'Anthony Phelan'],
      [1, 'Sandro Colarelli'],
      [1, 'Natalie Bassingthwaighte'],
      [1, 'Liz Blackett'],
      [1, 'Mike Bingaman'],
      [1, 'Christian McCarty'],
      [1, 'Tony Nixon'],
      [1, 'Andrea Moor'],
      [1, 'Mark Leonard Winter'],
      [1, 'Hugh Parker'],
      [1, 'Thomas Larkin'],
      [1, 'Christopher Sommers'],
      [1, 'Brad Leaver'],
      [1, 'Simon Mallory'],
      [1, 'Alex Knight'],

      [1, 'Elizabeth Cullen'],
      [1, 'Angie Milliken'],
      [1, 'Luke Corrin Care'],
      [1, 'Jack McGirr'],
      [1, 'Miranda Frangou'],
      [1, 'Ruby Gonzales-Judd'],
      [1, 'Greg Powell'],
      [1, 'Patrick Shearer'],
      [1, 'Sarah Ogden'],
      [1, 'Iain Gardiner'],
      [1, 'Melina Vidler'],

      [1, 'Libe Barer'],
      [1, 'Doug Burch'],
      [1, 'Cathy Cavadini'],
      [1, 'Joshua Fisk'],
      [1, 'Alex Radu'],
      [1, 'Katie-Jane Sharpe'],

      [1, 'Austin Butler'],
      [1, 'Tom Hanks'],
      [1, 'Olivia DeJonge'],
      [1, 'Helen Thomson'],
      [1, 'Richard Roxburgh'],
      [1, 'David Wenham'],
      [1, 'Kodi Smit-McPhee'],
      [1, 'Luke Bracey'],
      [1, 'Dacre Montgomery'],
      [1, 'Leon Ford'],

      [1, 'David Gannon'],
      [1, 'Charles Grounds'],
      [1, 'Josh McConville'],
      [1, 'Natasha Bassett'],
      [1, 'Xavier Samuel'],
      [1, 'Adam Dunn'],
      [1, 'Kate Mulvany'],
    ],
    synopsis: (
      <>
        <p>
          Choice Beigemeter™ exchange of the film, between Jimmie Rodgers Snow
          (Kodi Smit-McPhee), Hank Snow (David Wenham) and Colonel Tom Parker
          (Tom Hanks)
        </p>
        <p className="tab">
          <strong>Jimmie Rodgers Snow:</strong> Y'all have to hear this. Kids
          all over town are playing it everywhere I go.
        </p>
        <p className="tab">
          <strong>Hank Snow:</strong> I hear Negro rhythms.
        </p>
        <p className="tab">...</p>
        <p className="tab">
          <strong>Jimmie Rodgers Snow:</strong> Well, this fella's on the
          Hayride tonight, after me in the newcomer spot.
        </p>
        <p className="tab">
          <strong>Colonel Tom Parker:</strong> In Shreveport? No, they are not
          putting a colored boy on the Hayride.
        </p>
        <p className="tab">
          <strong>Jimmie Rodgers Snow:</strong> That's the thing. He's{' '}
          <em>white</em>.
        </p>
        <p className="tab">
          [Dramatic zoom on Colonel Tom Parker, dollar signs in his eyes]
        </p>
        <p className="tab">
          <strong>Colonel Tom Parker:</strong> He's... He's white? He's WHITE.
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt6710474',
    title: 'Everything Everywhere All at Once',
    directors: 'Dan Kwan, Daniel Scheinert',
    release_year: 2022,
    beige_index: 2,
    beige_score: 13.6765,
    beige_angle: 41.0885,
    beige_angle_string: '41.09 111.63 111.37',
    cast_count: 34,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'Sunita Mani'],
      [4, 'Peter Banifaz'],
      [3, 'Panuvat Anthony Nanakornpanom'],
      [3, 'Narayana Cabral'],
      [3, 'Craig Henningsen'],
      [3, 'Chelsey Goldsmith'],
      [2, 'Stephanie Hsu'],
      [2, 'Peter Boon Koh'],
      [2, 'Michiko Nishiwaki'],
      [2, 'Michelle Yeoh'],
      [2, 'Li Jing'],
      [2, 'Ke Huy Quan'],
      [2, 'Jane Lui'],
      [2, 'James Hong'],
      [2, 'Hiroshi Yada'],
      [2, 'Harry Shum Jr.'],
      [2, 'Dylan Henry Lau'],
      [2, 'Brian Le'],
      [2, 'Andy Le'],
      [1, 'Timothy Scott Ralston'],
      [1, 'Timothy Eulich'],
      [1, 'Tallie Medel'],
      [1, 'Randall Archer'],
      [1, 'Jenny Slate'],
      [1, 'Jason Hanmer'],
      [1, 'Jamie Lee Curtis'],
      [1, 'Efka Kvaraciejus'],
      [1, 'Daniel Scheinert'],
      [1, 'Dan Brown'],
      [1, 'Cara Marie Chooljian'],
      [1, 'Biff Wiff'],
      [1, 'Audrey Wasilewski'],
      [1, 'Anthony Molinari'],
      [1, 'Aaron Lazar'],
    ],
    synopsis: (
      <>
        <p>What’s Short Round and kicks ass with a fanny pack all over?</p>
        <p>
          Ke Huy Quan (Waymond Wang), that’s who. This legend started out as a
          child actor in <em>Indiana Jones and the Temple of Doom</em> (1984)
          and <em>The Goonies </em>(1985), before noping out in the 90s and
          transitioning to film production, with turns as a stunt choreographer
          on <em>X-Men</em> (2000) and <em>The One</em> (2001), before flirting
          with the auteur path as an assistant director on Wong Kar Wai’s
          sublime <em>2046 </em>(2004).
        </p>
        <p>
          As for the Ke Huy Quan-aissance, we have <em>Crazy Rich Asians </em>
          (2018) to thank for re-igniting his passion, by convincing him there
          might be more, and better, roles for Asian American actors this side
          of the century.
        </p>
        <img
          src="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/tt6710474_00.gif"
          alt="The Beige Index – Everything Everywhere All at Once"
          className="assessment-image"
        />
        <p>
          And what about the film’s lead? Well, there may just be{' '}
          <em>exactly one</em> actor in the entire multi-verse – beige or
          otherwise – who can sing, dance, fight, crack wise and deliver
          judgmental motherly pathos on cue and make us love her for it, and
          that’s Michelle Yeoh (Evelyn Wang).
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt14208870',
    title: 'The Fabelmans',
    directors: 'Steven Spielberg',
    release_year: 2022,
    beige_score: 13.74,
    beige_angle: 31.38,
    beige_index: 1,
    beige_angle_string: '31.38 111.63 111.37',
    cast_count: 42,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'Tia Nalls'],

      [3, 'Gustavo Escobar'],
      [3, 'Nicolas Cantu'],
      [3, 'Carlos Javier Castillo'],
      [3, 'Paul Chepikian'],

      [3, 'Lane Factor'],
      [3, 'Alex Quijano'],
      [3, 'Kalama Epstein'],
      [1, 'Connor Trinneer'],
      [1, 'Greg Grunberg'],
      [1, 'David Lynch'],
      [1, 'Jan Hoag'],
      [1, 'Ezra Buzzington'],
      [1, 'Brinly Marum'],
      [1, 'Mason Bumba'],
      [1, 'Mary M. Flynn'],
      [1, 'Larkin Campbell'],
      [1, 'Michelle Williams'],
      [1, 'Paul Dano'],
      [1, 'Seth Rogen'],
      [1, 'Gabriel LaBelle'],
      [1, 'Mateo Zoryan'],
      [1, 'Keeley Karsten'],
      [1, 'Alina Brace'],
      [1, 'Julia Butters'],
      [1, 'Cooper Dodson'],
      [1, 'Gabriel Bateman'],
      [1, 'Stephen Matthew Smith'],
      [1, 'James Urbaniak'],
      [1, 'Birdie Borria'],
      [1, 'Judd Hirsch'],
      [1, 'Sophia Kopera'],
      [1, 'Jeannie Berlin'],
      [1, 'Robin Bartlett'],
      [1, 'Sam Rechner'],
      [1, 'Oakes Fegley'],
      [1, 'Chloe East'],
      [1, 'Isabelle Kusman'],
      [1, 'Chandler Lovelle'],
    ],
    synopsis: (
      <>
        <p>
          In this lightly fictionalised tale of Steven Spielberg's adolescence,
          we glimpse the vicious anti-semitism the budding filmmaker suffered on
          his eventual path to auteurdom.
        </p>
        <p>
          Watch as Sam Fabelman (Gabriel LaBelle) films his cohort on Ditch Day
          and screens the finished picture at graduation: The beach. The waves.
          The gleaming idyll of white bodies. And in a therapist's wet dream,
          Fabelman frames his worst, most violent bully as impossibly mythic:
          the fastest, the greatest, the whitest of them all.
        </p>
        <p>
          Uh huh. Where else have we seen a Great White on the beach? Y'all
          heard it here first: Spielberg's white bullies were the inspiration
          for the greatest summer blockbuster of all time. JAWS
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt14444726',
    title: 'Tár',
    directors: 'Todd Field',
    release_year: 2022,
    beige_score: 13.9,
    beige_angle: 34.22,
    beige_index: 1,
    beige_angle_string: '34.22 111.63 111.37',
    cast_count: 76,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'Sarah Martin'],
      [4, 'Murali Perumal'],
      [3, 'Phongphairoj Lertsudwichai'],
      [3, 'Parami Mingmitpatanakun'],
      [3, 'Prapruttam Khumchat'],
      [3, 'Jevimary de Guia'],
      [3, 'Andreas Jentzsch'],
      [3, 'Zethphan D. Smith-Gneist'],
      [3, 'Joy Villanueva'],
      [3, 'Aldrin Poblete'],
      [3, 'Froilan Japano'],
      [3, 'Pattarawadee Thiwwatpakorn'],
      [3, 'Kenneth Won'],
      [3, 'An Cerne'],
      [2, 'Han Lai'],
      [2, 'Jasmine Leung'],
      [2, 'Chalee Sricharoen'],
      [2, 'Vivian Full'],
      [2, 'Wenteng Chang'],
      [2, 'Songha Choi'],
      [2, 'Rucheng Fan'],
      [2, 'Qu Yunrui'],

      [1, 'Kitty Watson'],
      [1, 'Alec Baldwin'],
      [1, 'Jessica Hansen'],
      [1, 'Nina Hoss'],
      [1, 'Mila Bogojevic'],
      [1, 'Alma Löhr'],
      [1, 'Sophie Kauer'],
      [1, 'Allan Corduner'],
      [1, 'Dorothea Plans Casal'],
      [1, 'Fabian Dirr'],
      [1, 'Julian Glover'],
      [1, 'Christian Höcherl'],
      [1, 'Jan Wolf'],
      [1, 'Peter Hering'],
      [1, 'Artjom Gilz'],
      [1, 'Daniel Söhner'],
      [1, 'Tilla Kratochwil'],
      [1, 'Marie-Lou Sellem'],
      [1, 'Marie-Anne Fliegel'],
      [1, 'André Röhner'],
      [1, 'Lydia Schamschula'],
      [1, 'Alexandra Montag'],
      [1, 'Rose Knox-Peebles'],
      [1, 'Frank Röth'],
      [1, 'Normann Höhne'],
      [1, 'Sven Rothe'],
      [1, 'Johann von Bülow'],
      [1, 'Oliver Mills'],
      [1, 'Xenia Assenza'],
      [1, 'Johanne Murdock'],
      [1, 'Sam Douglas'],
      [1, 'Burkhard Nitsch'],
      [1, 'Christoph Tomanek'],
      [1, 'Sarah Bauerett'],
      [1, 'Vincent Riotta'],
      [1, 'Ed White'],
      [1, 'Lucie Pohl'],
      [1, 'Lee R. Sellars'],
      [1, 'Ian Gallego'],

      [1, 'Christopher Lindner'],
      [1, 'Levin Petersen'],
      [1, 'Diana Birenyte'],
      [1, 'Tobias M Giesecke'],

      [1, 'Cate Blanchett'],
      [1, 'Noémie Merlant'],
      [1, 'Adam Gopnik'],
      [1, 'Marc-Martin Straub'],
      [1, 'Egon Brandstetter'],
      [1, 'Ylva Pollak'],
      [1, 'Natalie Ponudic'],
      [1, 'Sylvia Flote'],
      [1, 'Sydney Lemmon'],
      [1, 'Mark Strong'],
      [1, 'Nicolas Hopchet'],
    ],
    synopsis: (
      <>
        <p>
          It's a tale as old as time. Toxic person gets canceled. But because of
          their fame, their whiteness, 'canceled' just means an inconvenient
          relocation to a beiger country where the power structures remain
          grossly intact. The new colonialism.
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt1745960',
    title: 'Top Gun: Maverick',
    directors: 'Joseph Kosinski',
    release_year: 2022,
    beige_score: 16.15,
    beige_index: 2,
    beige_angle: 55.35,
    beige_angle_string: '55.35 111.63 111.37',
    cast_count: 44,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'Bashir Salahuddin'],
      [5, 'Charles Parnell'],
      [5, 'Jay Ellis'],
      [5, 'Tristan Henry'],
      [5, 'Chido Nwokocha'],
      [5, 'Chaz Ingram'],
      [5, 'Shannon Kane'],
      [5, 'Greg Tarzan Davis'],
      [5, 'Chelsea Harris'],
      [5, 'Darnell Kirkwood'],

      [3, 'Shantel Limbo'],
      [3, 'Danny Ramirez'],
      [3, 'Manny Jacinto'],
      [3, 'Stephanie Andrea Barron'],
      [2, 'Kara Wang'],
      [2, 'Raymond Lee'],
      [2, 'Whylip Lee'],
      [1, 'Lewis Pullman'],
      [1, 'Glen Powell'],
      [1, 'Jack Schumacher'],
      [1, 'Jake Picking'],
      [1, 'Jean Louisa Kelly'],
      [1, 'Lyliana Wray'],
      [1, 'Ed Harris'],

      [1, 'Austin Bowerman'],
      [1, 'Alec Williams'],
      [1, 'Rachel Winfree'],
      [1, 'Peter Mark Kendall'],
      [1, 'Ian Gary'],
      [1, 'Bob Stephenson'],
      [1, 'Landon Gordon'],
      [1, 'James Handy'],

      [1, 'Brian Ferguson'],
      [1, 'Rachael Markarian'],
      [1, 'Tom Cruise'],
      [1, 'Val Kilmer'],
      [1, 'Miles Teller'],
      [1, 'Jennifer Connelly'],
      [1, 'Jon Hamm'],
      [1, 'Monica Barbaro'],
    ],
    synopsis: (
      <>
        <p>
          Tarantino once described the original <em>Top Gun</em> (1986) as one
          man's journey toward choosing the Gay Way.
        </p>
        <p>
          We describe the journey from <em>Top Gun</em> to{' '}
          <em>Top Gun: Maverick</em> (2022) as Hollywood's journey to choosing
          the Beige Way, with a record cast of beige actors in this soaring
          legacyquel.
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt7322224',
    title: 'Triangle of Sadness',
    directors: 'Ruben Östlund',
    release_year: 2022,
    beige_score: 15.7,
    beige_angle: 51.23,
    beige_index: 2,

    beige_angle_string: '51.23 111.63 111.37',
    cast_count: 142,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [5, 'Papa Cheik Jade'],
      [5, 'Dauda Conteh'],
      [5, 'Beh Solo Kone'],
      [5, 'Yussif Zakaria'],
      [5, 'Jean-Christophe Folly'],
      [5, 'Chidiegwu Chidi'],
      [5, 'Augustine Kajue'],
      [5, 'Nana Manu'],
      [5, 'Malick Afocozi'],
      [5, 'Mohamed Lachras'],
      [5, 'Brian Kamara'],

      [4, 'Shaniaz Hama Ali'],
      [4, 'Arash Raoufi'],
      [4, 'Nafiseh Hadizadeh'],
      [4, 'Arvin Kananian'],
      [4, 'Anton Isaksson'],
      [3, 'Chand Smith'],

      [3, 'Filip Roseen'],
      [3, 'Daniel Estehghari'],
      [3, 'Isak Barrow'],
      [3, 'Alfred Lindstrom'],
      [3, 'Dolly De Leon'],
      [3, 'Maria Danica Herrera'],
      [3, 'Maria Grace Concepcion'],
      [3, 'Kirstin Delfinado'],
      [3, 'Shanilou Del Mundo'],
      [3, 'John Michael Yadao'],

      [3, 'Garry Villador Deveratturda'],
      [3, 'Christopher Janiola'],
      [3, 'Mario Rowen Bugtai'],
      [3, 'Nikolas Drosopoulos'],
      [3, 'Mimmi Brundin'],
      [3, 'Alexandros Sargologos'],
      [3, 'Allen Bandiola'],

      [3, 'John Paul Paugio'],

      [2, 'Jin Zou'],
      [2, 'Hamlet Talje Willoughby'],

      [1, 'Thobias Thorwid'],
      [1, 'Harris Dickinson'],
      [1, 'Charlbi Dean'],
      [1, 'Jiannis Moustos'],
      [1, 'Vicki Berlin'],
      [1, 'Timoleon Gketsos'],
      [1, 'Alicia Eriksson'],
      [1, 'Woody Harrelson'],
      [1, 'Zlatko Buric'],
      [1, 'Sunnyi Melles'],
      [1, 'Carolina Gynning'],
      [1, 'Iris Berben'],
      [1, 'Amanda Walker'],
      [1, 'Oliver Ford Davies'],
      [1, 'Ralph Schicha'],
      [1, 'Henrik Dorsin'],

      [1, 'Mia Benson'],
      [1, 'Stefan Gödicke'],
      [1, 'Fredrik Quiñones'],
      [1, 'Charlie Westerberg'],
      [1, 'Erik Andersson'],
      [1, 'Victor Kohler'],
      [1, 'Alfons Miari'],
      [1, 'Alexander Virenhem'],
      [1, 'Malte Gårdinger'],
      [1, 'William-Patrik Molvén'],
      [1, 'Florand Kaufeldt'],
      [1, 'Theodor Öhrn'],

      [1, 'Olof Källström'],
      [1, 'Julian Redaelli'],
      [1, 'Egil Ahlenius'],

      [1, 'Ludvig Fast'],
      [1, 'Victor Norlander'],
      [1, 'Eric Svirins'],

      [1, 'Simon Bredenberg'],
      [1, 'Noa del Castillo Hallberg'],
      [1, 'Ann-Sofi Back'],
      [1, 'Robert Rydberg'],
      [1, 'Robert Nordberg'],
      [1, 'Mira Uszkureit'],
      [1, 'Alex Schulman'],
      [1, 'Amanda Schulman'],
      [1, 'Emma Warg'],
      [1, 'Camilla Läckberg'],
      [1, 'Christina Saliba'],
      [1, 'Karin Myrenberg Faber'],
      [1, 'Linnea Olsson'],
      [1, 'Asta Stensson'],
      [1, 'Elsa Sjokvist'],
      [1, 'Johanna Ovelius'],
      [1, 'Hanna Oldenburg'],
      [1, 'Arnella Zetterstrom'],
      [1, 'Sepideh Mazloom'],

      [1, 'Chrysanthi Theodosi'],
      [1, 'Maria Alexiou'],
      [1, 'Marilena Lampropoulou'],
      [1, 'Christos Ntoulas'],
      [1, 'Nikolas Chalkiadakis'],

      [1, 'Fredrik Wikingsson'],
      [1, 'Henrik Thott'],

      [1, 'Magnus Jeansson'],
      [1, 'Stefan Martikainen'],

      [1, 'Linda Anborg'],

      [1, 'Alexia Mpogdanou'],

      [1, 'Achilles Vatrikas'],

      [1, 'Ellen Dixdotter'],
      [1, 'Ronja Kruus'],
      [1, 'Jacob Papinniemi'],
      [1, 'Chris Westerstrom'],
    ],
    synopsis: (
      <>
        <p>We're not going to lie. Watching this film unlocked a new kink.</p>
        <p>
          Dolly De Leon is our new beige queen, and we are thirsty to be her
          useless male concubines. Anytime. CALL US DOLLY.
        </p>
      </>
    ),
  },
  {
    imdb_id: 'tt13669038',
    title: 'Women Talking',
    directors: 'Sarah Polley',
    release_year: 2022,
    beige_score: 12,
    beige_angle: 0,
    beige_index: 1,
    beige_angle_string: '0 111.63 111.37',
    cast_count: 22,
    image:
      'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
    cast: [
      [1, 'Rooney Mara'],
      [1, 'Judith Ivey'],
      [1, 'Emily Mitchell'],
      [1, 'Kate Hallett'],
      [1, 'Liv McNeil'],
      [1, 'Claire Foy'],
      [1, 'Sheila McCarthy'],
      [1, 'Jessie Buckley'],
      [1, 'Michelle McLeod'],
      [1, 'Kira Guloien'],
      [1, 'Shayla Brown'],
      [1, 'Frances McDormand'],
      [1, 'Vivien Endicott Douglas'],
      [1, 'Ben Whishaw'],
      [1, 'August Winter'],
      [1, 'Lochlan Ray Miller'],
      [1, 'Nathaniel McParland'],
      [1, 'Will Bowes'],
      [1, 'Eli Ham'],
      [1, 'Emily Drake'],
      [1, 'Marcus Craig'],
      [1, 'Caroline Gillis'],
    ],
    synopsis: (
      <>
        <p>
          Our Beige Index Intern (Alan) suggested this punchline: "White Women
          Talking".
        </p>
        <p>Alan no longer works here.</p>
      </>
    ),
  },
];
