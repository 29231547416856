import React, { Component, Suspense } from 'react';
import CastContainer from '../components/castcontainer';
import BeigeMeter from '../components/beigemeter';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import { ButtonGroup, Tooltip } from '@mui/material';
import MediaQuery from 'react-responsive';
import { withRouter } from '../hooks/withrouter';
import { Helmet } from 'react-helmet-async';

import './thewhitewash-oscars.scss';

import { whitewashData } from '../data/whitewashdata';

const film = whitewashData;

class TheWhitewashOscars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beige_name: {
        1: 'CREAMY',
        2: 'BEIGE FEVER',
        3: 'MIDDLEBEIGE',
        4: 'DOWN TO BROWN',
        5: 'WELCOME TO BEIGEWATCH',
      },
      shareText: 'SHARE',
    };
  }

  synopsis() {
    return (
      <React.Fragment>
        <p>
          Wait, this isn't an Oscar-nominated film! It's a filthy <em>book</em>!
        </p>
        <p>
          And on top of that, isn't this a blatant, nay, <em>shameless</em> plug
          for our co-creator Siang Lu's <em>The Whitewash</em>, also available
          as an audiobook and starring, for the first time in Australian
          publishing history, a cast of fourteen talented – and #beigefever –
          actors?
        </p>
        <p>
          Surely this is against the rules! What's that? There <em>are</em> no
          rules? Oh well then carry on. Click the links to learn more. It'd make
          a perfect gift for your racist uncle!
        </p>
      </React.Fragment>
    );
  }

  componentDidUpdate = () => {
    const animateEl = document.getElementById('dial-animation');
    animateEl.beginElement();
  };

  renderDesktop() {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {film.title}&nbsp;&nbsp;
            </h1>
            <p className="inline-text director-text">
              By <span itemProp={'director'}>{film.directors}</span> (
              <span itemProp="copyrightYear">{film.release_year}</span>, UQP)
            </p>
          </span>
          <div>
            <Tooltip title="Click to copy link" placement="top">
              <Button
                variant="contained"
                disableElevation
                size="medium"
                aria-label="text button group"
                color="warning"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://thebeigeindex.com/film/${film.imdb_id}`
                  );
                }}
              >
                {this.state.shareText}&nbsp;
                <LinkIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="film-poster">
          <video width="100%" autoPlay loop muted>
            <source src={film.video} />
          </video>
          <div className="poster-buttons">
            <ButtonGroup
              aria-label="text button group"
              fullWidth={true}
              color="warning"
              variant="contained"
              orientation="vertical"
              disableElevation
            >
              <Button href={film.amazon} target="_blank" rel="noreferrer">
                <center>Buy a copy (Worldwide)</center>
              </Button>
              <Button href={film.aunz} target="_blank" rel="noreferrer">
                <center>Buy a copy (AU/NZ)</center>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          {this.synopsis()}
        </div>
        <div className="film-beige">
          <BeigeMeter
            beigeScore={film.beige_score}
            beigeAngle={film.beige_angle}
            beigeRotation={film.beige_angle_string}
          />
          <p id="beige-name">SCORE: {beige_name[2]}</p>
          <div className="cast-wrapper">
            <h5>Cast</h5>
            <CastContainer cast={film.cast} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMobile() {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {film.title}&nbsp;&nbsp;
            </h1>
          </span>
        </div>
        <div className="share-buttons">
          <Tooltip title="Click to copy link" placement="top">
            <Button
              variant="outlined"
              aria-label="text button group"
              color="warning"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://thebeigeindex.com/film/${film.imdb_id}`
                );
              }}
            >
              {this.state.shareText}&nbsp;
              <LinkIcon />
            </Button>
          </Tooltip>
        </div>
        <div className="film-poster">
          <video width="100%" autoPlay loop muted>
            <source src={film.video} />
          </video>
          <ButtonGroup
              aria-label="text button group"
              fullWidth={true}
              color="warning"
              variant="contained"
              orientation="vertical"
              disableElevation
            >
              <Button href={film.amazon} target="_blank" rel="noreferrer">
                <center>Buy a copy (Worldwide)</center>
              </Button>
              <Button href={film.aunz} target="_blank" rel="noreferrer">
                <center>Buy a copy (AU/NZ)</center>
              </Button>
            </ButtonGroup>
        </div>
        <div className="poster-buttons">
          <ButtonGroup
              aria-label="text button group"
              fullWidth={true}
              color="warning"
              variant="contained"
              orientation="vertical"
              disableElevation
            >
              <Button href={film.amazon} target="_blank" rel="noreferrer">
                <center>Buy a copy (Worldwide)</center>
              </Button>
              <Button href={film.aunz} target="_blank" rel="noreferrer">
                <center>Buy a copy (AU/NZ)</center>
              </Button>
            </ButtonGroup>
        </div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          <Suspense>{this.synopsis()}</Suspense>
          <p className="inline-text director-text">
            {film.title}, written by{' '}
            <span itemProp={'director'}>{film.directors}</span> (
            <span itemProp="copyrightYear">{film.release_year}</span>) is not
            ranked on the IMDb Top 250. Trust us. We checked.
          </p>
        </div>
        <div className="film-beige">
          <Paper elevation={1} id="meter-paper">
            <div id="meter-wrapper">
              <BeigeMeter
                beigeScore={film.beige_score}
                beigeAngle={film.beige_angle}
                beigeRotation={film.beige_angle_string}
              />
              <p id="beige-name">SCORE: {beige_name[2]}</p>
            </div>
          </Paper>
        </div>
        <div className="cast-wrapper">
          <h5>Cast</h5>
          <CastContainer cast={film.cast} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>The Beige Index – {film.title}</title>
        </Helmet>
        <div className="oscars">
          <Paper elevation={3}>
            <div
              className="whitewash-info-grid"
              itemScope={true}
              itemType={'http://schema.org/Movie'}
            >
              <MediaQuery minWidth={1001}>{this.renderDesktop()}</MediaQuery>
              <MediaQuery maxWidth={1000}>{this.renderMobile()}</MediaQuery>
            </div>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TheWhitewashOscars);
