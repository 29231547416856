import React, { Component } from 'react';

class FilmSpineFlexbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      classString: 'film-spine-flexbox-wrapper',
      colors: {
        '#f5e3cb': '#2b2b2b',
        '#e1c8aa': '#2b2b2b',
        '#c6a07d': '#2b2b2b',
        '#a27753': '#f5f5f5',
        '#645246': '#f5f5f5',
      },
    };
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  render() {
    const { data, selectFilm, classString } = this.props;
    const { colors } = this.state;

    const textColor = colors[data.beige_color];

    return (
      <div
        className={classString}
        id={data.imdb_id}
        onClick={() => {
          selectFilm(data.imdb_id);
          this.toggleClass();
        }}
        style={{ cursor: 'default' }}
      >
        <div
          className="film-spine-flexbox"
          style={{
            backgroundColor: data.beige_color,
            '--spine-bg-color': data.beige_color,
          }}
        >
          <div
            className="film-spine-text-flexbox noselect"
            style={{
              color: textColor,
            }}
          >
            {data.title}
          </div>
        </div>
      </div>
    );
  }
}

export default FilmSpineFlexbox;
