import React, { Component } from 'react';

import Paper from '@mui/material/Paper';

import './beigechart.scss';

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

class BeigeChartJS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: ['#f5e3cb', '#e1c8aa', '#c6a07d', '#a27753', '#645246'],
      axis_labels: {
        imdb_user_ranking: 'User Ranking',
        imdb_user_rating: 'User Rating',
        beige_score: 'Beige Score',
        gross_amount: 'Global Revenue',
        budget_amount: 'Budget',
        metascore: 'Metacritic Score',
        release_year: 'Release Year',
        title: 'Title',
        runtime: 'Runtime (mins)',
        cast_count: 'Cast Size',
      },
    };
  }

  render() {
    const { chartData, selectFilm, xLabel, yLabel } = this.props;
    const xAxisLabel = this.state.axis_labels[xLabel];
    const yAxisLabel = this.state.axis_labels[yLabel];
    // const key = xLabel + '-' + yLabel;

    const options = {
      scales: {
        y: {
          beginAtZero: false,
          grid: {
            color: 'rgba(245, 245, 245,0.25)',
            borderColor: 'rgba(245, 245, 245,0.5)',
            borderWidth: 1.5,
          },
          ticks: {
            color: 'rgba(245, 245, 245,1)',
            padding: 5,
            font: {
              family: 'Poppins',
              weight: '500',
              size: 12,
            },
          },
          title: {
            display: true,
            text: yAxisLabel,
            color: 'rgba(245, 245, 245,1)',
            font: {
              family: 'Poppins',
              weight: '500',
              size: 12,
            },
          },
        },
        x: {
          grid: {
            color: 'rgba(245, 245, 245,0.25)',
            borderColor: 'rgba(245, 245, 245,0.5)',
            borderWidth: 1.5,
          },
          ticks: {
            color: 'rgba(245, 245, 245,1)',
            padding: 5,
            font: {
              family: 'Poppins',
              weight: '500',
              size: 12,
            },
          },
          title: {
            display: true,
            text: xAxisLabel,
            color: 'rgba(245, 245, 245,1)',
            font: {
              family: 'Poppins',
              weight: '500',
              size: 12,
            },
          },
        },
      },
      backgroundColor: 'rgba(245, 245, 245, 0.25)',
      responsive: true,
      aspectRatio: 3 / 1,
      multiTooltipTemplate: '<%=datasetLabel%> : <%= value %>',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          bodyColor: 'rgba(245, 245, 245, 1)',
          bodyFont: {
            family: 'Poppins',
            weight: '500',
            size: 12,
          },
          callbacks: {
            label: function (context) {
              let thisLabel = context.dataset.label;
              return thisLabel;
            },
            afterLabel: function (context) {
              let thisLabel = '';
              thisLabel += xAxisLabel;
              thisLabel += ': ';
              thisLabel += context.parsed.x;
              thisLabel += '\n';
              thisLabel += yAxisLabel;
              thisLabel += ': ';
              thisLabel += context.parsed.y;

              return thisLabel;
            },
          },
        },
      },
      onClick: function (evt, activeElements) {
        selectFilm(chartData.datasets[activeElements[0].datasetIndex].imdb_id);
      },
    };

    return (
      <React.Fragment>
        <Paper className="beige-chart">
          <Scatter data={chartData} options={options} />
        </Paper>
      </React.Fragment>
    );
  }
}

export default BeigeChartJS;
