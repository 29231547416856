const s3link =
  'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/presslogos';

export const pressLogos = [
  {
    publication: 'Australian Financial Review',
    article: 'This cool new database dissects films in a unique way',
    logo: `${s3link}/afr.png`,
    link: 'https://www.afr.com/life-and-luxury/arts-and-culture/this-cool-new-database-dissects-films-in-a-unique-way-20220727-p5b552',
  },
  {
    publication: 'We Got This Covered',
    article:
      'Meet The Beige Index, a website that tracks just how white all your favorite movies are',
    logo: `${s3link}/wgtc.png`,
    link: 'https://wegotthiscovered.com/news/meet-the-beige-index-a-website-that-tracks-just-how-white-all-your-favorite-movies-are',
  },
  {
    publication: 'Murphy Live Magazine',
    article: 'Murphy Live Issue Two: The Beige Index',
    logo: `${s3link}/murphy.png`,
    link: 'https://www.murphylivemagazine.com/pastissues',
  },
  {
    publication: 'Pedestrian.tv',
    article:
      'We Watched 539 Hours Worth Of The Top IMDb Movies & Ranked Them By How Diverse Their Casts Were',
    logo: `${s3link}/pedestrian.png`,
    link: 'https://www.pedestrian.tv/entertainment/top-imdb-movies-ranked-diversity/',
  },
  {
    publication: "Women's Agenda",
    article: 'Beige Index judges how white our favourite movies are',
    logo: `${s3link}/womensagenda.png`,
    link: 'https://womensagenda.com.au/life/screen/beige-index-judges-how-white-our-favourite-movies-are',
  },
  {
    publication: 'ABC Radio National',
    article: 'Siang Lu on the whitewashing of the Asian film industry',
    logo: `${s3link}/abcrn.png`,
    link: 'https://www.abc.net.au/radionational/programs/big-weekend-of-books/siang-lu-on-the-whitewashing-of-the-asian-film-industry/14002998',
  },
];
