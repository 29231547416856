import React, { Component } from 'react';

class BeigeMeter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotationInit: '0 111.63 111.37',
    };
  }

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.65 130.04">
        <g id="c">
          <circle
            className="e"
            fill="#f5f5f5"
            cx="111.35"
            cy="111.05"
            r="10.44"
          />
          <path
            className="d"
            fill="#f5e3cb"
            d="M83.77,111.05c0,.09,0,.18,0,.28H0c0-24.4,7.85-46.97,21.17-65.32l67.6,49.12c-3.16,4.51-5,10-5,15.92Z"
          />
          <path
            className="g"
            fill="#e1c8aa"
            d="M102.44,84.76c-5.58,1.94-10.34,5.61-13.66,10.36L21.17,46C34.81,27.22,54.16,12.86,76.69,5.49l25.75,79.27Z"
          />
          <path
            className="f"
            fill="#c6a07d"
            d="M145.51,5.35l-25.7,79.1c-2.59-.8-5.35-1.23-8.2-1.23-3.21,0-6.3,.55-9.16,1.55L76.69,5.49C87.59,1.93,99.23,0,111.32,0s23.41,1.88,34.18,5.35Z"
          />
          <path
            className="h"
            fill="#a27753"
            d="M201.3,45.77l-67.24,48.85c-3.49-4.78-8.47-8.39-14.25-10.17L145.51,5.35c22.62,7.29,42.08,21.62,55.8,40.42Z"
          />
          <path
            className="i"
            fill="#645246"
            d="M139.43,111.32c0-.09,0-.18,0-.28,0-6.14-1.99-11.82-5.37-16.43l67.24-48.85c13.42,18.39,21.35,41.05,21.35,65.56h-83.22Z"
          />
          <g id="dial">
            <polygon
              className="e"
              fill="#f5f5f5"
              // transform={rotationString}
              points="110.85 117.11 27.85 111.64 27.85 111.02 110.85 105.56 110.85 117.11 110.85 117.11"
            />
            <animateTransform
              id="dial-animation"
              attributeName="transform"
              type="rotate"
              from={this.state.rotationInit}
              to={this.props.beigeRotation}
              dur="0.75s"
              fill="freeze"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default BeigeMeter;
