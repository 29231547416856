import React, { Component } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

class MuiSelect extends Component {
  handleChange = (event) => {
    let selectedOption = event.target.value;

    this.props.selectFunction(selectedOption);
  };

  render() {
    const { selectedOption, sortOptions, label } = this.props;
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            defaultValue={20}
            value={selectedOption}
            label={label}
            onChange={this.handleChange}
          >
            {/* Ennumerate options. */}
            {sortOptions.map((option, i) => (
              <MenuItem value={option.value} key={`${option.value}-${i}`}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }
}

export default MuiSelect;
