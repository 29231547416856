export const filmSort = [
  {
    "imdb_id": "tt0012349",
    "title": "The Kid",
    "beige_score": 12.0,
    "release_year": 1921,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 130,
    "budget_amount": 250000.0,
    "gross_amount": 41960.0,
    "metascore": null,
    "runtime": 68,
    "cast_count": 5,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0015324",
    "title": "Sherlock Jr.",
    "beige_score": 12.0,
    "release_year": 1924,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 194,
    "budget_amount": null,
    "gross_amount": null,
    "metascore": null,
    "runtime": 45,
    "cast_count": 5,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0015864",
    "title": "The Gold Rush",
    "beige_score": 12.0,
    "release_year": 1925,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 175,
    "budget_amount": 923000.0,
    "gross_amount": 27066.0,
    "metascore": null,
    "runtime": 95,
    "cast_count": 7,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0017925",
    "title": "The General",
    "beige_score": 12.0,
    "release_year": 1926,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 182,
    "budget_amount": null,
    "gross_amount": null,
    "metascore": null,
    "runtime": 67,
    "cast_count": 11,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0017136",
    "title": "Metropolis",
    "beige_score": 12.0,
    "release_year": 1927,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 117,
    "budget_amount": 1429650.0,
    "gross_amount": 1349711.0,
    "metascore": 98.0,
    "runtime": 153,
    "cast_count": 9,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0019254",
    "title": "The Passion of Joan of Arc",
    "beige_score": 12.0,
    "release_year": 1928,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 206,
    "budget_amount": null,
    "gross_amount": 21877.0,
    "metascore": null,
    "runtime": 110,
    "cast_count": 12,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0021749",
    "title": "City Lights",
    "beige_score": 13.1429,
    "release_year": 1931,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 51,
    "budget_amount": 1500000.0,
    "gross_amount": 50419.0,
    "metascore": 99.0,
    "runtime": 87,
    "cast_count": 7,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0022100",
    "title": "M",
    "beige_score": 12.0,
    "release_year": 1931,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 97,
    "budget_amount": null,
    "gross_amount": 35566.0,
    "metascore": null,
    "runtime": 117,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0025316",
    "title": "It Happened One Night",
    "beige_score": 12.0,
    "release_year": 1934,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 243,
    "budget_amount": 325000.0,
    "gross_amount": null,
    "metascore": 87.0,
    "runtime": 105,
    "cast_count": 11,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0027977",
    "title": "Modern Times",
    "beige_score": 12.4,
    "release_year": 1936,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 45,
    "budget_amount": 1500000.0,
    "gross_amount": 462337.0,
    "metascore": 96.0,
    "runtime": 87,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0031381",
    "title": "Gone with the Wind",
    "beige_score": 13.9149,
    "release_year": 1939,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 158,
    "budget_amount": 3977000.0,
    "gross_amount": 402382193.0,
    "metascore": 97.0,
    "runtime": 238,
    "cast_count": 47,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0032138",
    "title": "The Wizard of Oz",
    "beige_score": 12.0,
    "release_year": 1939,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 220,
    "budget_amount": 2777000.0,
    "gross_amount": 25637669.0,
    "metascore": 92.0,
    "runtime": 102,
    "cast_count": 12,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0031679",
    "title": "Mr. Smith Goes to Washington",
    "beige_score": 12.0,
    "release_year": 1939,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 191,
    "budget_amount": 1900000.0,
    "gross_amount": 144738.0,
    "metascore": 73.0,
    "runtime": 129,
    "cast_count": 31,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0032976",
    "title": "Rebecca",
    "beige_score": 12.0,
    "release_year": 1940,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 230,
    "budget_amount": 1288000.0,
    "gross_amount": 72275.0,
    "metascore": 86.0,
    "runtime": 130,
    "cast_count": 16,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0032551",
    "title": "The Grapes of Wrath",
    "beige_score": 12.0,
    "release_year": 1940,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 231,
    "budget_amount": 800000.0,
    "gross_amount": null,
    "metascore": 96.0,
    "runtime": 129,
    "cast_count": 37,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0032553",
    "title": "The Great Dictator",
    "beige_score": 12.0,
    "release_year": 1940,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 63,
    "budget_amount": 2000000.0,
    "gross_amount": 970263.0,
    "metascore": null,
    "runtime": 125,
    "cast_count": 26,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0033467",
    "title": "Citizen Kane",
    "beige_score": 12.0,
    "release_year": 1941,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 93,
    "budget_amount": 839727.0,
    "gross_amount": 1645133.0,
    "metascore": 100.0,
    "runtime": 119,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0034583",
    "title": "Casablanca",
    "beige_score": 13.2222,
    "release_year": 1942,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 42,
    "budget_amount": 950000.0,
    "gross_amount": 4497613.0,
    "metascore": 100.0,
    "runtime": 102,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0035446",
    "title": "To Be or Not to Be",
    "beige_score": 12.0,
    "release_year": 1942,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 228,
    "budget_amount": null,
    "gross_amount": 4578000.0,
    "metascore": 86.0,
    "runtime": 99,
    "cast_count": 15,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0036775",
    "title": "Double Indemnity",
    "beige_score": 12.0,
    "release_year": 1944,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 105,
    "budget_amount": 927262.0,
    "gross_amount": 14190.0,
    "metascore": 95.0,
    "runtime": 107,
    "cast_count": 10,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0036868",
    "title": "The Best Years of Our Lives",
    "beige_score": 12.0,
    "release_year": 1946,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 226,
    "budget_amount": 2100000.0,
    "gross_amount": 23656620.0,
    "metascore": 93.0,
    "runtime": 170,
    "cast_count": 26,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0038650",
    "title": "It's a Wonderful Life",
    "beige_score": 12.6667,
    "release_year": 1946,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 21,
    "budget_amount": 3180000.0,
    "gross_amount": 6184298.0,
    "metascore": 89.0,
    "runtime": 130,
    "cast_count": 39,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0040897",
    "title": "The Treasure of the Sierra Madre",
    "beige_score": 15.6364,
    "release_year": 1948,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 147,
    "budget_amount": 3000000.0,
    "gross_amount": 5014000.0,
    "metascore": 98.0,
    "runtime": 126,
    "cast_count": 11,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0040522",
    "title": "Bicycle Thieves",
    "beige_score": 12.0,
    "release_year": 1948,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 123,
    "budget_amount": 133000.0,
    "gross_amount": 436655.0,
    "metascore": null,
    "runtime": 89,
    "cast_count": 12,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0041959",
    "title": "The Third Man",
    "beige_score": 12.0,
    "release_year": 1949,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 179,
    "budget_amount": null,
    "gross_amount": 1227120.0,
    "metascore": 97.0,
    "runtime": 104,
    "cast_count": 12,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0042876",
    "title": "Rashomon",
    "beige_score": 15.0,
    "release_year": 1950,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 148,
    "budget_amount": 250000.0,
    "gross_amount": 46808.0,
    "metascore": 98.0,
    "runtime": 88,
    "cast_count": 8,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0042192",
    "title": "All About Eve",
    "beige_score": 12.0,
    "release_year": 1950,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 133,
    "budget_amount": 1400000.0,
    "gross_amount": 151052.0,
    "metascore": 98.0,
    "runtime": 138,
    "cast_count": 22,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0043014",
    "title": "Sunset Blvd.",
    "beige_score": 12.0,
    "release_year": 1950,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 60,
    "budget_amount": 1752000.0,
    "gross_amount": 300073.0,
    "metascore": null,
    "runtime": 110,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0045152",
    "title": "Singin' in the Rain",
    "beige_score": 12.8889,
    "release_year": 1952,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 85,
    "budget_amount": 2540800.0,
    "gross_amount": 1923485.0,
    "metascore": 99.0,
    "runtime": 103,
    "cast_count": 9,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0044741",
    "title": "Ikiru",
    "beige_score": 15.0,
    "release_year": 1952,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 108,
    "budget_amount": null,
    "gross_amount": 96302.0,
    "metascore": 91.0,
    "runtime": 143,
    "cast_count": 46,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0046268",
    "title": "The Wages of Fear",
    "beige_score": 15.0769,
    "release_year": 1953,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 192,
    "budget_amount": null,
    "gross_amount": 1098.0,
    "metascore": 85.0,
    "runtime": 131,
    "cast_count": 13,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0046438",
    "title": "Tokyo Story",
    "beige_score": 15.0,
    "release_year": 1953,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 209,
    "budget_amount": null,
    "gross_amount": 40468.0,
    "metascore": null,
    "runtime": 136,
    "cast_count": 30,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0047478",
    "title": "Seven Samurai",
    "beige_score": 15.0,
    "release_year": 1954,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 20,
    "budget_amount": 347222.0,
    "gross_amount": 346258.0,
    "metascore": 98.0,
    "runtime": 207,
    "cast_count": 98,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0047296",
    "title": "On the Waterfront",
    "beige_score": 12.0,
    "release_year": 1954,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 178,
    "budget_amount": null,
    "gross_amount": null,
    "metascore": 91.0,
    "runtime": 108,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0047396",
    "title": "Rear Window",
    "beige_score": 12.6667,
    "release_year": 1954,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 49,
    "budget_amount": 1000000.0,
    "gross_amount": 37034514.0,
    "metascore": 100.0,
    "runtime": 112,
    "cast_count": 15,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0046912",
    "title": "Dial M for Murder",
    "beige_score": 12.0,
    "release_year": 1954,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 163,
    "budget_amount": 1400000.0,
    "gross_amount": 31207.0,
    "metascore": 75.0,
    "runtime": 105,
    "cast_count": 10,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0048473",
    "title": "Pather Panchali",
    "beige_score": 24.0,
    "release_year": 1955,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 207,
    "budget_amount": null,
    "gross_amount": 135342.0,
    "metascore": null,
    "runtime": 125,
    "cast_count": 19,
    "beige_color": "#645246"
  },
  {
    "imdb_id": "tt0050212",
    "title": "The Bridge on the River Kwai",
    "beige_score": 14.4737,
    "release_year": 1957,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 165,
    "budget_amount": 3000000.0,
    "gross_amount": 27200000.0,
    "metascore": 87.0,
    "runtime": 161,
    "cast_count": 19,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0050986",
    "title": "Wild Strawberries",
    "beige_score": 12.0,
    "release_year": 1957,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 184,
    "budget_amount": null,
    "gross_amount": 14524.0,
    "metascore": 88.0,
    "runtime": 91,
    "cast_count": 24,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0051201",
    "title": "Witness for the Prosecution",
    "beige_score": 12.0,
    "release_year": 1957,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 64,
    "budget_amount": 3000000.0,
    "gross_amount": 7693.0,
    "metascore": null,
    "runtime": 116,
    "cast_count": 15,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0050825",
    "title": "Paths of Glory",
    "beige_score": 12.5882,
    "release_year": 1957,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 61,
    "budget_amount": 935000.0,
    "gross_amount": 5252.0,
    "metascore": 90.0,
    "runtime": 88,
    "cast_count": 17,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0050083",
    "title": "12 Angry Men",
    "beige_score": 12.0,
    "release_year": 1957,
    "imdb_user_rating": 9.0,
    "imdb_user_ranking": 5,
    "budget_amount": 350000.0,
    "gross_amount": 955.0,
    "metascore": 96.0,
    "runtime": 96,
    "cast_count": 13,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0050976",
    "title": "The Seventh Seal",
    "beige_score": 12.0,
    "release_year": 1957,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 198,
    "budget_amount": 150000.0,
    "gross_amount": 303309.0,
    "metascore": 88.0,
    "runtime": 96,
    "cast_count": 17,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0052357",
    "title": "Vertigo",
    "beige_score": 12.0,
    "release_year": 1958,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 100,
    "budget_amount": 2479000.0,
    "gross_amount": 7797728.0,
    "metascore": 100.0,
    "runtime": 128,
    "cast_count": 9,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0052618",
    "title": "Ben-Hur",
    "beige_score": 12.0,
    "release_year": 1959,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 181,
    "budget_amount": 15000000.0,
    "gross_amount": 74437720.0,
    "metascore": 90.0,
    "runtime": 212,
    "cast_count": 17,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0053198",
    "title": "The 400 Blows",
    "beige_score": 12.0,
    "release_year": 1959,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 236,
    "budget_amount": null,
    "gross_amount": 127244.0,
    "metascore": null,
    "runtime": 99,
    "cast_count": 27,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0053291",
    "title": "Some Like It Hot",
    "beige_score": 12.0,
    "release_year": 1959,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 131,
    "budget_amount": 2883848.0,
    "gross_amount": 195088.0,
    "metascore": 98.0,
    "runtime": 121,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0053125",
    "title": "North by Northwest",
    "beige_score": 12.0,
    "release_year": 1959,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 98,
    "budget_amount": 3101000.0,
    "gross_amount": 142319.0,
    "metascore": 98.0,
    "runtime": 136,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0054215",
    "title": "Psycho",
    "beige_score": 12.0,
    "release_year": 1960,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 32,
    "budget_amount": 806947.0,
    "gross_amount": 32052925.0,
    "metascore": 97.0,
    "runtime": 109,
    "cast_count": 13,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0053604",
    "title": "The Apartment",
    "beige_score": 12.0,
    "release_year": 1960,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 106,
    "budget_amount": 3000000.0,
    "gross_amount": 18778738.0,
    "metascore": 94.0,
    "runtime": 125,
    "cast_count": 16,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0055630",
    "title": "Yojimbo",
    "beige_score": 15.0,
    "release_year": 1961,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 146,
    "budget_amount": null,
    "gross_amount": 46808.0,
    "metascore": 93.0,
    "runtime": 110,
    "cast_count": 50,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0055031",
    "title": "Judgment at Nuremberg",
    "beige_score": 12.0,
    "release_year": 1961,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 136,
    "budget_amount": 3000000.0,
    "gross_amount": 12180.0,
    "metascore": 60.0,
    "runtime": 179,
    "cast_count": 25,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0056058",
    "title": "Hara-Kiri",
    "beige_score": 15.0,
    "release_year": 1962,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 46,
    "budget_amount": null,
    "gross_amount": 15222.0,
    "metascore": 85.0,
    "runtime": 133,
    "cast_count": 35,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0056592",
    "title": "To Kill a Mockingbird",
    "beige_score": 15.0,
    "release_year": 1962,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 109,
    "budget_amount": 2000000.0,
    "gross_amount": 599146.0,
    "metascore": 88.0,
    "runtime": 129,
    "cast_count": 18,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0056172",
    "title": "Lawrence of Arabia",
    "beige_score": 14.9091,
    "release_year": 1962,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 95,
    "budget_amount": 15000000.0,
    "gross_amount": 45716692.0,
    "metascore": 100.0,
    "runtime": 218,
    "cast_count": 22,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0057565",
    "title": "High and Low",
    "beige_score": 15.0,
    "release_year": 1963,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 92,
    "budget_amount": null,
    "gross_amount": 46808.0,
    "metascore": 90.0,
    "runtime": 143,
    "cast_count": 48,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0057115",
    "title": "The Great Escape",
    "beige_score": 12.0,
    "release_year": 1963,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 149,
    "budget_amount": 4000000.0,
    "gross_amount": 228178.0,
    "metascore": 86.0,
    "runtime": 172,
    "cast_count": 27,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0057012",
    "title": "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    "beige_score": 12.9,
    "release_year": 1964,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 68,
    "budget_amount": 1800000.0,
    "gross_amount": 9523464.0,
    "metascore": 97.0,
    "runtime": 95,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0059578",
    "title": "For a Few Dollars More",
    "beige_score": 12.0,
    "release_year": 1965,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 127,
    "budget_amount": 600000.0,
    "gross_amount": 15000000.0,
    "metascore": 74.0,
    "runtime": 132,
    "cast_count": 21,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0059742",
    "title": "The Sound of Music",
    "beige_score": 12.0,
    "release_year": 1965,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 242,
    "budget_amount": 8200000.0,
    "gross_amount": 159428329.0,
    "metascore": 63.0,
    "runtime": 172,
    "cast_count": 21,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0058946",
    "title": "The Battle of Algiers",
    "beige_score": 20.5714,
    "release_year": 1966,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 229,
    "budget_amount": 800000.0,
    "gross_amount": 962002.0,
    "metascore": 96.0,
    "runtime": 121,
    "cast_count": 7,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt0060196",
    "title": "The Good, the Bad and the Ugly",
    "beige_score": 12.7619,
    "release_year": 1966,
    "imdb_user_rating": 8.8,
    "imdb_user_ranking": 10,
    "budget_amount": 1200000.0,
    "gross_amount": 25253887.0,
    "metascore": 90.0,
    "runtime": 161,
    "cast_count": 21,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0060827",
    "title": "Persona",
    "beige_score": 12.0,
    "release_year": 1966,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 239,
    "budget_amount": null,
    "gross_amount": 90556.0,
    "metascore": 86.0,
    "runtime": 85,
    "cast_count": 4,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0061512",
    "title": "Cool Hand Luke",
    "beige_score": 12.0,
    "release_year": 1967,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 233,
    "budget_amount": 3200000.0,
    "gross_amount": 67.0,
    "metascore": 92.0,
    "runtime": 127,
    "cast_count": 24,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0062622",
    "title": "2001: A Space Odyssey",
    "beige_score": 12.0,
    "release_year": 1968,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 89,
    "budget_amount": 12000000.0,
    "gross_amount": 65877808.0,
    "metascore": 84.0,
    "runtime": 149,
    "cast_count": 32,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0064116",
    "title": "Once Upon a Time in the West",
    "beige_score": 13.5294,
    "release_year": 1968,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 47,
    "budget_amount": 5000000.0,
    "gross_amount": 5434825.0,
    "metascore": 80.0,
    "runtime": 165,
    "cast_count": 17,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0066921",
    "title": "A Clockwork Orange",
    "beige_score": 12.0,
    "release_year": 1971,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 102,
    "budget_amount": 2200000.0,
    "gross_amount": 26960374.0,
    "metascore": 77.0,
    "runtime": 136,
    "cast_count": 46,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0068646",
    "title": "The Godfather",
    "beige_score": 12.0,
    "release_year": 1972,
    "imdb_user_rating": 9.2,
    "imdb_user_ranking": 2,
    "budget_amount": 6000000.0,
    "gross_amount": 250341816.0,
    "metascore": 100.0,
    "runtime": 175,
    "cast_count": 44,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0070735",
    "title": "The Sting",
    "beige_score": 14.25,
    "release_year": 1973,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 110,
    "budget_amount": 5500000.0,
    "gross_amount": 156000000.0,
    "metascore": 83.0,
    "runtime": 129,
    "cast_count": 32,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0070047",
    "title": "The Exorcist",
    "beige_score": 12.0,
    "release_year": 1973,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 224,
    "budget_amount": 11000000.0,
    "gross_amount": 441306145.0,
    "metascore": 81.0,
    "runtime": 122,
    "cast_count": 24,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0071315",
    "title": "Chinatown",
    "beige_score": 12.4146,
    "release_year": 1974,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 157,
    "budget_amount": 6000000.0,
    "gross_amount": 29225935.0,
    "metascore": 92.0,
    "runtime": 130,
    "cast_count": 41,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0071562",
    "title": "The Godfather: Part II",
    "beige_score": 12.4444,
    "release_year": 1974,
    "imdb_user_rating": 9.0,
    "imdb_user_ranking": 4,
    "budget_amount": 13000000.0,
    "gross_amount": 47960085.0,
    "metascore": 90.0,
    "runtime": 202,
    "cast_count": 72,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0071853",
    "title": "Monty Python and the Holy Grail",
    "beige_score": 12.2143,
    "release_year": 1975,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 144,
    "budget_amount": 510167.0,
    "gross_amount": 1940906.0,
    "metascore": 91.0,
    "runtime": 91,
    "cast_count": 35,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0072684",
    "title": "Barry Lyndon",
    "beige_score": 12.0,
    "release_year": 1975,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 193,
    "budget_amount": 11000000.0,
    "gross_amount": 198992.0,
    "metascore": 89.0,
    "runtime": 185,
    "cast_count": 44,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0073195",
    "title": "Jaws",
    "beige_score": 12.0,
    "release_year": 1975,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 205,
    "budget_amount": 7000000.0,
    "gross_amount": 471411300.0,
    "metascore": 87.0,
    "runtime": 124,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0073486",
    "title": "One Flew Over the Cuckoo's Nest",
    "beige_score": 14.5938,
    "release_year": 1975,
    "imdb_user_rating": 8.7,
    "imdb_user_ranking": 18,
    "budget_amount": 3000000.0,
    "gross_amount": 109114817.0,
    "metascore": 84.0,
    "runtime": 133,
    "cast_count": 32,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0071411",
    "title": "Dersu Uzala",
    "beige_score": 12.4138,
    "release_year": 1975,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 237,
    "budget_amount": 4000000.0,
    "gross_amount": 14480.0,
    "metascore": null,
    "runtime": 142,
    "cast_count": 29,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0075314",
    "title": "Taxi Driver",
    "beige_score": 14.027,
    "release_year": 1976,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 111,
    "budget_amount": 1300000.0,
    "gross_amount": 28570902.0,
    "metascore": 94.0,
    "runtime": 114,
    "cast_count": 37,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0075148",
    "title": "Rocky",
    "beige_score": 16.25,
    "release_year": 1976,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 212,
    "budget_amount": 960000.0,
    "gross_amount": 117250402.0,
    "metascore": 70.0,
    "runtime": 120,
    "cast_count": 40,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0074958",
    "title": "Network",
    "beige_score": 13.05,
    "release_year": 1976,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 219,
    "budget_amount": 3800000.0,
    "gross_amount": 23690757.0,
    "metascore": 83.0,
    "runtime": 121,
    "cast_count": 40,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0076759",
    "title": "Star Wars",
    "beige_score": 12.5143,
    "release_year": 1977,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 28,
    "budget_amount": 11000000.0,
    "gross_amount": 775398007.0,
    "metascore": 90.0,
    "runtime": 121,
    "cast_count": 35,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0077416",
    "title": "The Deer Hunter",
    "beige_score": 15.2766,
    "release_year": 1978,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 187,
    "budget_amount": 15000000.0,
    "gross_amount": 49074379.0,
    "metascore": 86.0,
    "runtime": 183,
    "cast_count": 47,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0078788",
    "title": "Apocalypse Now",
    "beige_score": 13.875,
    "release_year": 1979,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 55,
    "budget_amount": 31500000.0,
    "gross_amount": 92158064.0,
    "metascore": 94.0,
    "runtime": 147,
    "cast_count": 48,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0078748",
    "title": "Alien",
    "beige_score": 16.0,
    "release_year": 1979,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 50,
    "budget_amount": 11000000.0,
    "gross_amount": 106285522.0,
    "metascore": 89.0,
    "runtime": 117,
    "cast_count": 9,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0079470",
    "title": "Life of Brian",
    "beige_score": 12.0,
    "release_year": 1979,
    "imdb_user_rating": 8.0,
    "imdb_user_ranking": 241,
    "budget_amount": 4000000.0,
    "gross_amount": 20745728.0,
    "metascore": 77.0,
    "runtime": 94,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0080678",
    "title": "The Elephant Man",
    "beige_score": 12.0526,
    "release_year": 1980,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 154,
    "budget_amount": 5000000.0,
    "gross_amount": 26023860.0,
    "metascore": 78.0,
    "runtime": 124,
    "cast_count": 57,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0081505",
    "title": "The Shining",
    "beige_score": 13.6364,
    "release_year": 1980,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 62,
    "budget_amount": 19000000.0,
    "gross_amount": 47299460.0,
    "metascore": 66.0,
    "runtime": 146,
    "cast_count": 22,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0080684",
    "title": "Star Wars: Episode V - The Empire Strikes Back",
    "beige_score": 12.8182,
    "release_year": 1980,
    "imdb_user_rating": 8.7,
    "imdb_user_ranking": 15,
    "budget_amount": 18000000.0,
    "gross_amount": 538375067.0,
    "metascore": 82.0,
    "runtime": 124,
    "cast_count": 44,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0081398",
    "title": "Raging Bull",
    "beige_score": 12.809,
    "release_year": 1980,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 156,
    "budget_amount": 18000000.0,
    "gross_amount": 23402427.0,
    "metascore": 89.0,
    "runtime": 129,
    "cast_count": 89,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0082971",
    "title": "Indiana Jones and the Raiders of the Lost Ark",
    "beige_score": 14.7297,
    "release_year": 1981,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 56,
    "budget_amount": 18000000.0,
    "gross_amount": 389925971.0,
    "metascore": 85.0,
    "runtime": 115,
    "cast_count": 37,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0082096",
    "title": "The Boat",
    "beige_score": 12.0,
    "release_year": 1981,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 77,
    "budget_amount": 13913043.0,
    "gross_amount": 11487676.0,
    "metascore": 86.0,
    "runtime": 149,
    "cast_count": 37,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0084787",
    "title": "The Thing",
    "beige_score": 13.2,
    "release_year": 1982,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 161,
    "budget_amount": 15000000.0,
    "gross_amount": 19632053.0,
    "metascore": 57.0,
    "runtime": 109,
    "cast_count": 16,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0083987",
    "title": "Gandhi",
    "beige_score": 18.3288,
    "release_year": 1982,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 246,
    "budget_amount": 22000000.0,
    "gross_amount": 52767889.0,
    "metascore": 79.0,
    "runtime": 191,
    "cast_count": 146,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt0083658",
    "title": "Blade Runner",
    "beige_score": 13.75,
    "release_year": 1982,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 176,
    "budget_amount": 30000000.0,
    "gross_amount": 41722424.0,
    "metascore": 84.0,
    "runtime": 117,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0086250",
    "title": "Scarface",
    "beige_score": 14.7898,
    "release_year": 1983,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 107,
    "budget_amount": 25000000.0,
    "gross_amount": 65884703.0,
    "metascore": 65.0,
    "runtime": 170,
    "cast_count": 88,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0086190",
    "title": "Star Wars: Episode VI - Return of the Jedi",
    "beige_score": 13.2444,
    "release_year": 1983,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 88,
    "budget_amount": 32500000.0,
    "gross_amount": 475106177.0,
    "metascore": 58.0,
    "runtime": 131,
    "cast_count": 90,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0086879",
    "title": "Amadeus",
    "beige_score": 12.0,
    "release_year": 1984,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 74,
    "budget_amount": 18000000.0,
    "gross_amount": 52066791.0,
    "metascore": 88.0,
    "runtime": 160,
    "cast_count": 88,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0088247",
    "title": "The Terminator",
    "beige_score": 13.9535,
    "release_year": 1984,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 215,
    "budget_amount": 6400000.0,
    "gross_amount": 78371200.0,
    "metascore": 84.0,
    "runtime": 107,
    "cast_count": 43,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0087843",
    "title": "Once Upon a Time in America",
    "beige_score": 12.0,
    "release_year": 1984,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 80,
    "budget_amount": 30000000.0,
    "gross_amount": 5473337.0,
    "metascore": 75.0,
    "runtime": 229,
    "cast_count": 60,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0091251",
    "title": "Come and See",
    "beige_score": 12.0,
    "release_year": 1985,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 103,
    "budget_amount": null,
    "gross_amount": 20929648.0,
    "metascore": null,
    "runtime": 142,
    "cast_count": 31,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0088763",
    "title": "Back to the Future",
    "beige_score": 14.7273,
    "release_year": 1985,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 30,
    "budget_amount": 19000000.0,
    "gross_amount": 383336762.0,
    "metascore": 87.0,
    "runtime": 116,
    "cast_count": 44,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0089881",
    "title": "Ran",
    "beige_score": 15.0,
    "release_year": 1985,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 140,
    "budget_amount": 11500000.0,
    "gross_amount": 4164283.0,
    "metascore": 96.0,
    "runtime": 162,
    "cast_count": 32,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0092005",
    "title": "Stand by Me",
    "beige_score": 12.0,
    "release_year": 1986,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 214,
    "budget_amount": 8000000.0,
    "gross_amount": 52287414.0,
    "metascore": 75.0,
    "runtime": 89,
    "cast_count": 31,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0090605",
    "title": "Aliens",
    "beige_score": 14.0769,
    "release_year": 1986,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 66,
    "budget_amount": 18500000.0,
    "gross_amount": 131060248.0,
    "metascore": 84.0,
    "runtime": 137,
    "cast_count": 26,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0091763",
    "title": "Platoon",
    "beige_score": 16.5405,
    "release_year": 1986,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 211,
    "budget_amount": 6000000.0,
    "gross_amount": 138545632.0,
    "metascore": 92.0,
    "runtime": 120,
    "cast_count": 38,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0093058",
    "title": "Full Metal Jacket",
    "beige_score": 17.6081,
    "release_year": 1987,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 104,
    "budget_amount": 30000000.0,
    "gross_amount": 46357676.0,
    "metascore": 76.0,
    "runtime": 116,
    "cast_count": 37,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0095765",
    "title": "Cinema Paradiso",
    "beige_score": 12.0,
    "release_year": 1988,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 53,
    "budget_amount": 5000000.0,
    "gross_amount": 13019063.0,
    "metascore": 80.0,
    "runtime": 155,
    "cast_count": 29,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0095327",
    "title": "Grave of the Fireflies",
    "beige_score": 15.0,
    "release_year": 1988,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 48,
    "budget_amount": 3700000.0,
    "gross_amount": 516962.0,
    "metascore": 94.0,
    "runtime": 89,
    "cast_count": 47,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0095016",
    "title": "Die Hard",
    "beige_score": 13.4576,
    "release_year": 1988,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 120,
    "budget_amount": 28000000.0,
    "gross_amount": 141603197.0,
    "metascore": 72.0,
    "runtime": 132,
    "cast_count": 59,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0096283",
    "title": "My Neighbor Totoro",
    "beige_score": 14.7391,
    "release_year": 1988,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 172,
    "budget_amount": 3700000.0,
    "gross_amount": 30574632.0,
    "metascore": 86.0,
    "runtime": 86,
    "cast_count": 21,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0097165",
    "title": "Dead Poets Society",
    "beige_score": 12.0,
    "release_year": 1989,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 210,
    "budget_amount": 16400000.0,
    "gross_amount": 235860116.0,
    "metascore": 79.0,
    "runtime": 128,
    "cast_count": 51,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0097576",
    "title": "Indiana Jones and the Last Crusade",
    "beige_score": 12.8333,
    "release_year": 1989,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 122,
    "budget_amount": 48000000.0,
    "gross_amount": 474171806.0,
    "metascore": 65.0,
    "runtime": 127,
    "cast_count": 48,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0099348",
    "title": "Dances with Wolves",
    "beige_score": 16.0851,
    "release_year": 1990,
    "imdb_user_rating": 8.0,
    "imdb_user_ranking": 250,
    "budget_amount": null,
    "gross_amount": null,
    "metascore": 72.0,
    "runtime": 181,
    "cast_count": 47,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0099685",
    "title": "Goodfellas",
    "beige_score": 12.3942,
    "release_year": 1990,
    "imdb_user_rating": 8.7,
    "imdb_user_ranking": 17,
    "budget_amount": 25000000.0,
    "gross_amount": 47036784.0,
    "metascore": 90.0,
    "runtime": 145,
    "cast_count": 137,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0103064",
    "title": "Terminator 2: Judgment Day",
    "beige_score": 14.7,
    "release_year": 1991,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 29,
    "budget_amount": 102000000.0,
    "gross_amount": 520881154.0,
    "metascore": 75.0,
    "runtime": 137,
    "cast_count": 50,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0102926",
    "title": "The Silence of the Lambs",
    "beige_score": 14.717,
    "release_year": 1991,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 22,
    "budget_amount": 19000000.0,
    "gross_amount": 272742922.0,
    "metascore": 85.0,
    "runtime": 118,
    "cast_count": 53,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0101414",
    "title": "Beauty and the Beast",
    "beige_score": 12.6667,
    "release_year": 1991,
    "imdb_user_rating": 8.0,
    "imdb_user_ranking": 249,
    "budget_amount": 25000000.0,
    "gross_amount": 424967620.0,
    "metascore": 95.0,
    "runtime": 84,
    "cast_count": 39,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0105695",
    "title": "Unforgiven",
    "beige_score": 13.0732,
    "release_year": 1992,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 137,
    "budget_amount": 14400000.0,
    "gross_amount": 159167799.0,
    "metascore": 85.0,
    "runtime": 130,
    "cast_count": 41,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0105236",
    "title": "Reservoir Dogs",
    "beige_score": 13.1304,
    "release_year": 1992,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 90,
    "budget_amount": 1200000.0,
    "gross_amount": 2913644.0,
    "metascore": 79.0,
    "runtime": 99,
    "cast_count": 23,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0103639",
    "title": "Aladdin",
    "beige_score": 12.0,
    "release_year": 1992,
    "imdb_user_rating": 8.0,
    "imdb_user_ranking": 245,
    "budget_amount": 28000000.0,
    "gross_amount": 504050219.0,
    "metascore": 86.0,
    "runtime": 90,
    "cast_count": 15,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0107207",
    "title": "In the Name of the Father",
    "beige_score": 12.3462,
    "release_year": 1993,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 189,
    "budget_amount": 13000000.0,
    "gross_amount": 65796862.0,
    "metascore": 84.0,
    "runtime": 133,
    "cast_count": 104,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0107048",
    "title": "Groundhog Day",
    "beige_score": 12.9167,
    "release_year": 1993,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 222,
    "budget_amount": 14600000.0,
    "gross_amount": 71108591.0,
    "metascore": 72.0,
    "runtime": 101,
    "cast_count": 48,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0108052",
    "title": "Schindler's List",
    "beige_score": 12.048,
    "release_year": 1993,
    "imdb_user_rating": 9.0,
    "imdb_user_ranking": 6,
    "budget_amount": 22000000.0,
    "gross_amount": 322161245.0,
    "metascore": 94.0,
    "runtime": 195,
    "cast_count": 125,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0107290",
    "title": "Jurassic Park",
    "beige_score": 14.5,
    "release_year": 1993,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 151,
    "budget_amount": 63000000.0,
    "gross_amount": 1099559202.0,
    "metascore": 68.0,
    "runtime": 127,
    "cast_count": 22,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0111161",
    "title": "The Shawshank Redemption",
    "beige_score": 13.3091,
    "release_year": 1994,
    "imdb_user_rating": 9.3,
    "imdb_user_ranking": 1,
    "budget_amount": 25000000.0,
    "gross_amount": 28884504.0,
    "metascore": 81.0,
    "runtime": 142,
    "cast_count": 55,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0110912",
    "title": "Pulp Fiction",
    "beige_score": 14.7609,
    "release_year": 1994,
    "imdb_user_rating": 8.9,
    "imdb_user_ranking": 8,
    "budget_amount": 8000000.0,
    "gross_amount": 213928762.0,
    "metascore": 94.0,
    "runtime": 154,
    "cast_count": 46,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0110357",
    "title": "The Lion King",
    "beige_score": 16.2381,
    "release_year": 1994,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 36,
    "budget_amount": 45000000.0,
    "gross_amount": 1063611805.0,
    "metascore": 88.0,
    "runtime": 88,
    "cast_count": 21,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0109830",
    "title": "Forrest Gump",
    "beige_score": 15.5517,
    "release_year": 1994,
    "imdb_user_rating": 8.8,
    "imdb_user_ranking": 11,
    "budget_amount": 55000000.0,
    "gross_amount": 678226133.0,
    "metascore": 82.0,
    "runtime": 142,
    "cast_count": 116,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0110413",
    "title": "L\u00e9on: The Professional",
    "beige_score": 15.0667,
    "release_year": 1994,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 34,
    "budget_amount": 22115385.0,
    "gross_amount": 19552639.0,
    "metascore": 64.0,
    "runtime": 110,
    "cast_count": 60,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0114814",
    "title": "The Usual Suspects",
    "beige_score": 13.1935,
    "release_year": 1995,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 39,
    "budget_amount": 6000000.0,
    "gross_amount": 23341568.0,
    "metascore": 77.0,
    "runtime": 106,
    "cast_count": 31,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0112471",
    "title": "Before Sunrise",
    "beige_score": 12.5714,
    "release_year": 1995,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 186,
    "budget_amount": 2500000.0,
    "gross_amount": 5987386.0,
    "metascore": 77.0,
    "runtime": 101,
    "cast_count": 28,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0112641",
    "title": "Casino",
    "beige_score": 12.3382,
    "release_year": 1995,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 138,
    "budget_amount": 52000000.0,
    "gross_amount": 116112375.0,
    "metascore": 73.0,
    "runtime": 178,
    "cast_count": 136,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0114709",
    "title": "Toy Story",
    "beige_score": 12.0,
    "release_year": 1995,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 75,
    "budget_amount": 30000000.0,
    "gross_amount": 394436586.0,
    "metascore": 95.0,
    "runtime": 81,
    "cast_count": 32,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0113247",
    "title": "La Haine",
    "beige_score": 16.46,
    "release_year": 1995,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 238,
    "budget_amount": 3453333.0,
    "gross_amount": 412914.0,
    "metascore": null,
    "runtime": 98,
    "cast_count": 50,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0113277",
    "title": "Heat",
    "beige_score": 15.254,
    "release_year": 1995,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 115,
    "budget_amount": 60000000.0,
    "gross_amount": 187436818.0,
    "metascore": 76.0,
    "runtime": 170,
    "cast_count": 63,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0112573",
    "title": "Braveheart",
    "beige_score": 12.0,
    "release_year": 1995,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 73,
    "budget_amount": 72000000.0,
    "gross_amount": 213216216.0,
    "metascore": 68.0,
    "runtime": 178,
    "cast_count": 59,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0114369",
    "title": "Se7en",
    "beige_score": 17.1091,
    "release_year": 1995,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 19,
    "budget_amount": 33000000.0,
    "gross_amount": 327333559.0,
    "metascore": 65.0,
    "runtime": 127,
    "cast_count": 55,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0117951",
    "title": "Trainspotting",
    "beige_score": 12.6923,
    "release_year": 1996,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 168,
    "budget_amount": 2343750.0,
    "gross_amount": 16767475.0,
    "metascore": 83.0,
    "runtime": 93,
    "cast_count": 26,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0116282",
    "title": "Fargo",
    "beige_score": 13.4474,
    "release_year": 1996,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 171,
    "budget_amount": 7000000.0,
    "gross_amount": 60611975.0,
    "metascore": 85.0,
    "runtime": 98,
    "cast_count": 38,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0119698",
    "title": "Princess Mononoke",
    "beige_score": 15.0,
    "release_year": 1997,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 79,
    "budget_amount": 19824880.0,
    "gross_amount": 169785704.0,
    "metascore": 76.0,
    "runtime": 134,
    "cast_count": 66,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0119217",
    "title": "Good Will Hunting",
    "beige_score": 13.275,
    "release_year": 1997,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 82,
    "budget_amount": 10000000.0,
    "gross_amount": 225933435.0,
    "metascore": 70.0,
    "runtime": 126,
    "cast_count": 40,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0118849",
    "title": "Children of Heaven",
    "beige_score": 22.0,
    "release_year": 1997,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 180,
    "budget_amount": 180000.0,
    "gross_amount": 933933.0,
    "metascore": 77.0,
    "runtime": 89,
    "cast_count": 46,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt0118799",
    "title": "Life Is Beautiful",
    "beige_score": 12.0,
    "release_year": 1997,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 25,
    "budget_amount": 20000000.0,
    "gross_amount": 230098753.0,
    "metascore": 59.0,
    "runtime": 116,
    "cast_count": 52,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0119488",
    "title": "L.A. Confidential",
    "beige_score": 13.2965,
    "release_year": 1997,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 112,
    "budget_amount": 35000000.0,
    "gross_amount": 126216940.0,
    "metascore": 90.0,
    "runtime": 138,
    "cast_count": 86,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0120586",
    "title": "American History X",
    "beige_score": 16.1463,
    "release_year": 1998,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 38,
    "budget_amount": 20000000.0,
    "gross_amount": 23875127.0,
    "metascore": 62.0,
    "runtime": 119,
    "cast_count": 41,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0120735",
    "title": "Lock, Stock and Two Smoking Barrels",
    "beige_score": 13.8837,
    "release_year": 1998,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 162,
    "budget_amount": 1600000.0,
    "gross_amount": 3753929.0,
    "metascore": 66.0,
    "runtime": 107,
    "cast_count": 43,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0118715",
    "title": "The Big Lebowski",
    "beige_score": 14.675,
    "release_year": 1998,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 200,
    "budget_amount": 15000000.0,
    "gross_amount": 46969081.0,
    "metascore": 71.0,
    "runtime": 117,
    "cast_count": 40,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0120815",
    "title": "Saving Private Ryan",
    "beige_score": 12.1059,
    "release_year": 1998,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 24,
    "budget_amount": 70000000.0,
    "gross_amount": 482349603.0,
    "metascore": 91.0,
    "runtime": 169,
    "cast_count": 85,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0120382",
    "title": "The Truman Show",
    "beige_score": 14.7826,
    "release_year": 1998,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 145,
    "budget_amount": 60000000.0,
    "gross_amount": 264118201.0,
    "metascore": 90.0,
    "runtime": 103,
    "cast_count": 69,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0137523",
    "title": "Fight Club",
    "beige_score": 16.283,
    "release_year": 1999,
    "imdb_user_rating": 8.8,
    "imdb_user_ranking": 12,
    "budget_amount": 63000000.0,
    "gross_amount": 101209702.0,
    "metascore": 66.0,
    "runtime": 139,
    "cast_count": 53,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0133093",
    "title": "The Matrix",
    "beige_score": 16.25,
    "release_year": 1999,
    "imdb_user_rating": 8.7,
    "imdb_user_ranking": 16,
    "budget_amount": 63000000.0,
    "gross_amount": 467222728.0,
    "metascore": 73.0,
    "runtime": 136,
    "cast_count": 36,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0167404",
    "title": "The Sixth Sense",
    "beige_score": 13.8537,
    "release_year": 1999,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 142,
    "budget_amount": 40000000.0,
    "gross_amount": 672806432.0,
    "metascore": 64.0,
    "runtime": 107,
    "cast_count": 41,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0120689",
    "title": "The Green Mile",
    "beige_score": 13.3889,
    "release_year": 1999,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 26,
    "budget_amount": 60000000.0,
    "gross_amount": 286801374.0,
    "metascore": 61.0,
    "runtime": 189,
    "cast_count": 36,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0169547",
    "title": "American Beauty",
    "beige_score": 13.4688,
    "release_year": 1999,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 67,
    "budget_amount": 15000000.0,
    "gross_amount": 356296601.0,
    "metascore": 84.0,
    "runtime": 122,
    "cast_count": 32,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0245712",
    "title": "Amores perros",
    "beige_score": 19.5778,
    "release_year": 2000,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 234,
    "budget_amount": 2000000.0,
    "gross_amount": 20908467.0,
    "metascore": 83.0,
    "runtime": 154,
    "cast_count": 45,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt0208092",
    "title": "Snatch",
    "beige_score": 13.614,
    "release_year": 2000,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 118,
    "budget_amount": 8571429.0,
    "gross_amount": 83557872.0,
    "metascore": 55.0,
    "runtime": 104,
    "cast_count": 57,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0209144",
    "title": "Memento",
    "beige_score": 12.0,
    "release_year": 2000,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 54,
    "budget_amount": 9000000.0,
    "gross_amount": 40047078.0,
    "metascore": 80.0,
    "runtime": 113,
    "cast_count": 13,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0180093",
    "title": "Requiem for a Dream",
    "beige_score": 15.7115,
    "release_year": 2000,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 83,
    "budget_amount": 4500000.0,
    "gross_amount": 7390108.0,
    "metascore": 68.0,
    "runtime": 102,
    "cast_count": 53,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0172495",
    "title": "Gladiator",
    "beige_score": 13.5,
    "release_year": 2000,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 37,
    "budget_amount": 103000000.0,
    "gross_amount": 465380802.0,
    "metascore": 67.0,
    "runtime": 155,
    "cast_count": 36,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0198781",
    "title": "Monsters, Inc.",
    "beige_score": 12.45,
    "release_year": 2001,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 204,
    "budget_amount": 115000000.0,
    "gross_amount": 579707738.0,
    "metascore": 79.0,
    "runtime": 92,
    "cast_count": 40,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0268978",
    "title": "A Beautiful Mind",
    "beige_score": 12.0,
    "release_year": 2001,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 143,
    "budget_amount": 58000000.0,
    "gross_amount": 316791257.0,
    "metascore": 72.0,
    "runtime": 135,
    "cast_count": 58,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0211915",
    "title": "Am\u00e9lie",
    "beige_score": 12.2985,
    "release_year": 2001,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 101,
    "budget_amount": 10000000.0,
    "gross_amount": 174118254.0,
    "metascore": 69.0,
    "runtime": 122,
    "cast_count": 67,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0245429",
    "title": "Spirited Away",
    "beige_score": 15.0,
    "release_year": 2001,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 31,
    "budget_amount": 19000000.0,
    "gross_amount": 355725195.0,
    "metascore": 96.0,
    "runtime": 125,
    "cast_count": 48,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0120737",
    "title": "The Lord of the Rings: The Fellowship of the Ring",
    "beige_score": 12.8929,
    "release_year": 2001,
    "imdb_user_rating": 8.8,
    "imdb_user_ranking": 9,
    "budget_amount": 93000000.0,
    "gross_amount": 898094742.0,
    "metascore": 92.0,
    "runtime": 178,
    "cast_count": 56,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0264464",
    "title": "Catch Me If You Can",
    "beige_score": 12.8725,
    "release_year": 2002,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 177,
    "budget_amount": 52000000.0,
    "gross_amount": 352114312.0,
    "metascore": 75.0,
    "runtime": 141,
    "cast_count": 102,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0317248",
    "title": "City of God",
    "beige_score": 21.2297,
    "release_year": 2002,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 23,
    "budget_amount": 7857143.0,
    "gross_amount": 30680793.0,
    "metascore": 79.0,
    "runtime": 130,
    "cast_count": 148,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt0253474",
    "title": "The Pianist",
    "beige_score": 12.0,
    "release_year": 2002,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 33,
    "budget_amount": 35000000.0,
    "gross_amount": 120072577.0,
    "metascore": 85.0,
    "runtime": 150,
    "cast_count": 78,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0167261",
    "title": "The Lord of the Rings: The Two Towers",
    "beige_score": 12.8,
    "release_year": 2002,
    "imdb_user_rating": 8.8,
    "imdb_user_ranking": 14,
    "budget_amount": 94000000.0,
    "gross_amount": 947896241.0,
    "metascore": 87.0,
    "runtime": 179,
    "cast_count": 50,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0364569",
    "title": "Oldboy",
    "beige_score": 15.0,
    "release_year": 2003,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 72,
    "budget_amount": 3000000.0,
    "gross_amount": 15194593.0,
    "metascore": 77.0,
    "runtime": 120,
    "cast_count": 53,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0266543",
    "title": "Finding Nemo",
    "beige_score": 12.0,
    "release_year": 2003,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 155,
    "budget_amount": 94000000.0,
    "gross_amount": 940352645.0,
    "metascore": 90.0,
    "runtime": 100,
    "cast_count": 24,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0353969",
    "title": "Memories of Murder",
    "beige_score": 15.0,
    "release_year": 2003,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 195,
    "budget_amount": 2800000.0,
    "gross_amount": 1166717.0,
    "metascore": 82.0,
    "runtime": 131,
    "cast_count": 83,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0266697",
    "title": "Kill Bill: Vol. 1",
    "beige_score": 14.8718,
    "release_year": 2003,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 152,
    "budget_amount": 30000000.0,
    "gross_amount": 180906076.0,
    "metascore": 69.0,
    "runtime": 111,
    "cast_count": 39,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0167260",
    "title": "The Lord of the Rings: The Return of the King",
    "beige_score": 12.6897,
    "release_year": 2003,
    "imdb_user_rating": 9.0,
    "imdb_user_ranking": 7,
    "budget_amount": 94000000.0,
    "gross_amount": 1146436214.0,
    "metascore": 94.0,
    "runtime": 201,
    "cast_count": 58,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0325980",
    "title": "Pirates of the Caribbean: The Curse of the Black Pearl",
    "beige_score": 14.7344,
    "release_year": 2003,
    "imdb_user_rating": 8.0,
    "imdb_user_ranking": 235,
    "budget_amount": 140000000.0,
    "gross_amount": 654264015.0,
    "metascore": 63.0,
    "runtime": 143,
    "cast_count": 64,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0395169",
    "title": "Hotel Rwanda",
    "beige_score": 25.8649,
    "release_year": 2004,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 208,
    "budget_amount": 17500000.0,
    "gross_amount": 33882243.0,
    "metascore": 79.0,
    "runtime": 121,
    "cast_count": 37,
    "beige_color": "#645246"
  },
  {
    "imdb_id": "tt0363163",
    "title": "Downfall",
    "beige_score": 12.0,
    "release_year": 2004,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 126,
    "budget_amount": 16676550.0,
    "gross_amount": 92181574.0,
    "metascore": 82.0,
    "runtime": 156,
    "cast_count": 90,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0347149",
    "title": "Howl's Moving Castle",
    "beige_score": 14.5238,
    "release_year": 2004,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 166,
    "budget_amount": 24000000.0,
    "gross_amount": 236323601.0,
    "metascore": 80.0,
    "runtime": 119,
    "cast_count": 16,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0381681",
    "title": "Before Sunset",
    "beige_score": 12.0,
    "release_year": 2004,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 225,
    "budget_amount": 2700000.0,
    "gross_amount": 15936507.0,
    "metascore": 90.0,
    "runtime": 80,
    "cast_count": 10,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0317705",
    "title": "The Incredibles",
    "beige_score": 13.4444,
    "release_year": 2004,
    "imdb_user_rating": 8.0,
    "imdb_user_ranking": 227,
    "budget_amount": 92000000.0,
    "gross_amount": 631607053.0,
    "metascore": 90.0,
    "runtime": 115,
    "cast_count": 45,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0338013",
    "title": "Eternal Sunshine of the Spotless Mind",
    "beige_score": 13.0,
    "release_year": 2004,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 91,
    "budget_amount": 20000000.0,
    "gross_amount": 74036715.0,
    "metascore": 89.0,
    "runtime": 108,
    "cast_count": 18,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0405159",
    "title": "Million Dollar Baby",
    "beige_score": 14.5581,
    "release_year": 2004,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 174,
    "budget_amount": 30000000.0,
    "gross_amount": 216763646.0,
    "metascore": 86.0,
    "runtime": 132,
    "cast_count": 43,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0434409",
    "title": "V for Vendetta",
    "beige_score": 12.3733,
    "release_year": 2005,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 159,
    "budget_amount": 54000000.0,
    "gross_amount": 132511035.0,
    "metascore": 62.0,
    "runtime": 132,
    "cast_count": 75,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0476735",
    "title": "My Father and My Son",
    "beige_score": 22.0,
    "release_year": 2005,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 240,
    "budget_amount": null,
    "gross_amount": 18612999.0,
    "metascore": null,
    "runtime": 112,
    "cast_count": 47,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt0372784",
    "title": "Batman Begins",
    "beige_score": 14.8717,
    "release_year": 2005,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 128,
    "budget_amount": 150000000.0,
    "gross_amount": 373672993.0,
    "metascore": 70.0,
    "runtime": 140,
    "cast_count": 100,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0407887",
    "title": "The Departed",
    "beige_score": 13.119,
    "release_year": 2006,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 40,
    "budget_amount": 90000000.0,
    "gross_amount": 291480452.0,
    "metascore": 85.0,
    "runtime": 151,
    "cast_count": 84,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0482571",
    "title": "The Prestige",
    "beige_score": 12.0667,
    "release_year": 2006,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 41,
    "budget_amount": 40000000.0,
    "gross_amount": 109676311.0,
    "metascore": 66.0,
    "runtime": 130,
    "cast_count": 45,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0457430",
    "title": "Pan's Labyrinth",
    "beige_score": 12.2581,
    "release_year": 2006,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 139,
    "budget_amount": 19000000.0,
    "gross_amount": 83862032.0,
    "metascore": 98.0,
    "runtime": 118,
    "cast_count": 31,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0405094",
    "title": "The Lives of Others",
    "beige_score": 12.5,
    "release_year": 2006,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 59,
    "budget_amount": 2000000.0,
    "gross_amount": 77356942.0,
    "metascore": 89.0,
    "runtime": 137,
    "cast_count": 36,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0758758",
    "title": "Into the Wild",
    "beige_score": 13.2222,
    "release_year": 2007,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 218,
    "budget_amount": 15000000.0,
    "gross_amount": 56675895.0,
    "metascore": 73.0,
    "runtime": 148,
    "cast_count": 36,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0382932",
    "title": "Ratatouille",
    "beige_score": 12.1364,
    "release_year": 2007,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 223,
    "budget_amount": 150000000.0,
    "gross_amount": 623726085.0,
    "metascore": 96.0,
    "runtime": 111,
    "cast_count": 22,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0986264",
    "title": "Like Stars on Earth",
    "beige_score": 24.0,
    "release_year": 2007,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 125,
    "budget_amount": null,
    "gross_amount": 21897373.0,
    "metascore": null,
    "runtime": 165,
    "cast_count": 39,
    "beige_color": "#645246"
  },
  {
    "imdb_id": "tt0477348",
    "title": "No Country for Old Men",
    "beige_score": 13.6818,
    "release_year": 2007,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 153,
    "budget_amount": 25000000.0,
    "gross_amount": 171627166.0,
    "metascore": 92.0,
    "runtime": 122,
    "cast_count": 44,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0469494",
    "title": "There Will Be Blood",
    "beige_score": 12.0,
    "release_year": 2007,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 141,
    "budget_amount": 25000000.0,
    "gross_amount": 76182388.0,
    "metascore": 93.0,
    "runtime": 158,
    "cast_count": 51,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0910970",
    "title": "WALL\u00b7E",
    "beige_score": 13.4,
    "release_year": 2008,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 58,
    "budget_amount": 180000000.0,
    "gross_amount": 521311890.0,
    "metascore": 95.0,
    "runtime": 98,
    "cast_count": 20,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1205489",
    "title": "Gran Torino",
    "beige_score": 15.5294,
    "release_year": 2008,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 169,
    "budget_amount": 33000000.0,
    "gross_amount": 269958228.0,
    "metascore": 72.0,
    "runtime": 116,
    "cast_count": 51,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt0468569",
    "title": "The Dark Knight",
    "beige_score": 15.1031,
    "release_year": 2008,
    "imdb_user_rating": 9.0,
    "imdb_user_ranking": 3,
    "budget_amount": 185000000.0,
    "gross_amount": 1006102277.0,
    "metascore": 84.0,
    "runtime": 152,
    "cast_count": 97,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt1305806",
    "title": "The Secret in Their Eyes",
    "beige_score": 19.7419,
    "release_year": 2009,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 164,
    "budget_amount": 2000000.0,
    "gross_amount": 35079650.0,
    "metascore": 80.0,
    "runtime": 129,
    "cast_count": 31,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt1187043",
    "title": "3 Idiots",
    "beige_score": 23.84,
    "release_year": 2009,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 87,
    "budget_amount": 11361289.0,
    "gross_amount": 60262836.0,
    "metascore": 67.0,
    "runtime": 170,
    "cast_count": 75,
    "beige_color": "#645246"
  },
  {
    "imdb_id": "tt0978762",
    "title": "Mary and Max",
    "beige_score": 13.4444,
    "release_year": 2009,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 203,
    "budget_amount": 10430380.0,
    "gross_amount": 1740429.0,
    "metascore": null,
    "runtime": 92,
    "cast_count": 27,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0361748",
    "title": "Inglourious Basterds",
    "beige_score": 12.9333,
    "release_year": 2009,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 78,
    "budget_amount": 70000000.0,
    "gross_amount": 321457747.0,
    "metascore": 69.0,
    "runtime": 153,
    "cast_count": 60,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1049413",
    "title": "Up",
    "beige_score": 13.4444,
    "release_year": 2009,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 113,
    "budget_amount": 175000000.0,
    "gross_amount": 735099102.0,
    "metascore": 88.0,
    "runtime": 96,
    "cast_count": 27,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1028532",
    "title": "Hachi: A Dog's Tale",
    "beige_score": 12.913,
    "release_year": 2009,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 232,
    "budget_amount": 16000000.0,
    "gross_amount": 46749646.0,
    "metascore": null,
    "runtime": 93,
    "cast_count": 23,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1130884",
    "title": "Shutter Island",
    "beige_score": 14.2564,
    "release_year": 2010,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 150,
    "budget_amount": 80000000.0,
    "gross_amount": 294805697.0,
    "metascore": 63.0,
    "runtime": 138,
    "cast_count": 39,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt1375666",
    "title": "Inception",
    "beige_score": 13.5294,
    "release_year": 2010,
    "imdb_user_rating": 8.8,
    "imdb_user_ranking": 13,
    "budget_amount": 160000000.0,
    "gross_amount": 836848102.0,
    "metascore": 74.0,
    "runtime": 148,
    "cast_count": 51,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0435761",
    "title": "Toy Story 3",
    "beige_score": 12.8333,
    "release_year": 2010,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 84,
    "budget_amount": 200000000.0,
    "gross_amount": 1066970811.0,
    "metascore": 92.0,
    "runtime": 103,
    "cast_count": 48,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1255953",
    "title": "Incendies",
    "beige_score": 20.8415,
    "release_year": 2010,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 121,
    "budget_amount": 6800000.0,
    "gross_amount": 6788659.0,
    "metascore": 80.0,
    "runtime": 131,
    "cast_count": 82,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt0892769",
    "title": "How to Train Your Dragon",
    "beige_score": 12.6154,
    "release_year": 2010,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 201,
    "budget_amount": 165000000.0,
    "gross_amount": 494879471.0,
    "metascore": 75.0,
    "runtime": 98,
    "cast_count": 13,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1291584",
    "title": "Warrior",
    "beige_score": 15.55,
    "release_year": 2011,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 170,
    "budget_amount": 25000000.0,
    "gross_amount": 23308615.0,
    "metascore": 71.0,
    "runtime": 140,
    "cast_count": 60,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt1832382",
    "title": "A Separation",
    "beige_score": 22.0,
    "release_year": 2011,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 119,
    "budget_amount": 500000.0,
    "gross_amount": 22926076.0,
    "metascore": 95.0,
    "runtime": 123,
    "cast_count": 37,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt1454029",
    "title": "The Help",
    "beige_score": 18.1132,
    "release_year": 2011,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 248,
    "budget_amount": 25000000.0,
    "gross_amount": 216639112.0,
    "metascore": 62.0,
    "runtime": 146,
    "cast_count": 53,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt1201607",
    "title": "Harry Potter and the Deathly Hallows: Part 2",
    "beige_score": 12.72,
    "release_year": 2011,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 188,
    "budget_amount": 125000000.0,
    "gross_amount": 1342359942.0,
    "metascore": 85.0,
    "runtime": 130,
    "cast_count": 100,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1675434",
    "title": "The Intouchables",
    "beige_score": 14.2895,
    "release_year": 2011,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 44,
    "budget_amount": 13227800.0,
    "gross_amount": 426588510.0,
    "metascore": 57.0,
    "runtime": 112,
    "cast_count": 38,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt2106476",
    "title": "The Hunt",
    "beige_score": 12.0,
    "release_year": 2012,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 96,
    "budget_amount": 3800000.0,
    "gross_amount": 15843274.0,
    "metascore": 77.0,
    "runtime": 115,
    "cast_count": 35,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1853728",
    "title": "Django Unchained",
    "beige_score": 17.813,
    "release_year": 2012,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 57,
    "budget_amount": 100000000.0,
    "gross_amount": 426074373.0,
    "metascore": 81.0,
    "runtime": 165,
    "cast_count": 82,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt1345836",
    "title": "The Dark Knight Rises",
    "beige_score": 14.5425,
    "release_year": 2012,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 69,
    "budget_amount": 250000000.0,
    "gross_amount": 1081153097.0,
    "metascore": 78.0,
    "runtime": 164,
    "cast_count": 106,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt1979320",
    "title": "Rush",
    "beige_score": 12.4789,
    "release_year": 2013,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 216,
    "budget_amount": 38000000.0,
    "gross_amount": 96983009.0,
    "metascore": 74.0,
    "runtime": 123,
    "cast_count": 71,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt0993846",
    "title": "The Wolf of Wall Street",
    "beige_score": 13.3209,
    "release_year": 2013,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 135,
    "budget_amount": 100000000.0,
    "gross_amount": 392000694.0,
    "metascore": 75.0,
    "runtime": 180,
    "cast_count": 134,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt2024544",
    "title": "12 Years a Slave",
    "beige_score": 21.069,
    "release_year": 2013,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 183,
    "budget_amount": 20000000.0,
    "gross_amount": 187733202.0,
    "metascore": 96.0,
    "runtime": 134,
    "cast_count": 58,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt1392214",
    "title": "Prisoners",
    "beige_score": 15.7647,
    "release_year": 2013,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 173,
    "budget_amount": 46000000.0,
    "gross_amount": 122126687.0,
    "metascore": 70.0,
    "runtime": 153,
    "cast_count": 34,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt3011894",
    "title": "Wild Tales",
    "beige_score": 20.0,
    "release_year": 2014,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 199,
    "budget_amount": 3300000.0,
    "gross_amount": 30642704.0,
    "metascore": 77.0,
    "runtime": 122,
    "cast_count": 69,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt2582802",
    "title": "Whiplash",
    "beige_score": 19.7838,
    "release_year": 2014,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 43,
    "budget_amount": 3300000.0,
    "gross_amount": 49396747.0,
    "metascore": 88.0,
    "runtime": 106,
    "cast_count": 37,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt0816692",
    "title": "Interstellar",
    "beige_score": 13.4483,
    "release_year": 2014,
    "imdb_user_rating": 8.6,
    "imdb_user_ranking": 27,
    "budget_amount": 165000000.0,
    "gross_amount": 701729206.0,
    "metascore": 74.0,
    "runtime": 169,
    "cast_count": 29,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt2267998",
    "title": "Gone Girl",
    "beige_score": 13.3256,
    "release_year": 2014,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 185,
    "budget_amount": 61000000.0,
    "gross_amount": 369330363.0,
    "metascore": 79.0,
    "runtime": 149,
    "cast_count": 43,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt2278388",
    "title": "The Grand Budapest Hotel",
    "beige_score": 12.3535,
    "release_year": 2014,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 190,
    "budget_amount": 25000000.0,
    "gross_amount": 173082189.0,
    "metascore": 88.0,
    "runtime": 99,
    "cast_count": 99,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt3170832",
    "title": "Room",
    "beige_score": 13.8571,
    "release_year": 2015,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 196,
    "budget_amount": 13000000.0,
    "gross_amount": 35401758.0,
    "metascore": 86.0,
    "runtime": 118,
    "cast_count": 21,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1895587",
    "title": "Spotlight",
    "beige_score": 13.1418,
    "release_year": 2015,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 217,
    "budget_amount": 20000000.0,
    "gross_amount": 98690254.0,
    "metascore": 93.0,
    "runtime": 129,
    "cast_count": 67,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1392190",
    "title": "Mad Max: Fury Road",
    "beige_score": 12.963,
    "release_year": 2015,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 202,
    "budget_amount": 150000000.0,
    "gross_amount": 375709470.0,
    "metascore": 90.0,
    "runtime": 120,
    "cast_count": 45,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt2096673",
    "title": "Inside Out",
    "beige_score": 14.0893,
    "release_year": 2015,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 160,
    "budget_amount": 175000000.0,
    "gross_amount": 858848019.0,
    "metascore": 94.0,
    "runtime": 95,
    "cast_count": 56,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt5311514",
    "title": "Your Name.",
    "beige_score": 15.0,
    "release_year": 2016,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 86,
    "budget_amount": 3067300.0,
    "gross_amount": 358180115.0,
    "metascore": 79.0,
    "runtime": 106,
    "cast_count": 40,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt5074352",
    "title": "Dangal",
    "beige_score": 24.2222,
    "release_year": 2016,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 129,
    "budget_amount": 10290000.0,
    "gross_amount": 303723636.0,
    "metascore": null,
    "runtime": 161,
    "cast_count": 27,
    "beige_color": "#645246"
  },
  {
    "imdb_id": "tt4016934",
    "title": "The Handmaiden",
    "beige_score": 14.9455,
    "release_year": 2016,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 244,
    "budget_amount": 8500000.0,
    "gross_amount": 37767206.0,
    "metascore": 84.0,
    "runtime": 145,
    "cast_count": 55,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt2119532",
    "title": "Hacksaw Ridge",
    "beige_score": 12.7,
    "release_year": 2016,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 197,
    "budget_amount": 40000000.0,
    "gross_amount": 180563636.0,
    "metascore": 71.0,
    "runtime": 139,
    "cast_count": 90,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt2380307",
    "title": "Coco",
    "beige_score": 19.4783,
    "release_year": 2017,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 76,
    "budget_amount": 175000000.0,
    "gross_amount": 807817888.0,
    "metascore": 81.0,
    "runtime": 105,
    "cast_count": 23,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt3315342",
    "title": "Logan",
    "beige_score": 15.8851,
    "release_year": 2017,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 221,
    "budget_amount": 97000000.0,
    "gross_amount": 619179950.0,
    "metascore": 77.0,
    "runtime": 137,
    "cast_count": 87,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt5027774",
    "title": "Three Billboards Outside Ebbing, Missouri",
    "beige_score": 15.9333,
    "release_year": 2017,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 167,
    "budget_amount": 15000000.0,
    "gross_amount": 160260573.0,
    "metascore": 88.0,
    "runtime": 115,
    "cast_count": 30,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt4154756",
    "title": "Avengers: Infinity War",
    "beige_score": 16.8684,
    "release_year": 2018,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 65,
    "budget_amount": 321000000.0,
    "gross_amount": 2048359754.0,
    "metascore": 68.0,
    "runtime": 149,
    "cast_count": 57,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt6966692",
    "title": "Green Book",
    "beige_score": 15.7385,
    "release_year": 2018,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 134,
    "budget_amount": 23000000.0,
    "gross_amount": 321752656.0,
    "metascore": 69.0,
    "runtime": 130,
    "cast_count": 65,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt8267604",
    "title": "Capernaum",
    "beige_score": 22.1391,
    "release_year": 2018,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 94,
    "budget_amount": 4000000.0,
    "gross_amount": 64417003.0,
    "metascore": 75.0,
    "runtime": 126,
    "cast_count": 115,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt4633694",
    "title": "Spider-Man: Into the Spider-Verse",
    "beige_score": 19.3581,
    "release_year": 2018,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 70,
    "budget_amount": 90000000.0,
    "gross_amount": 375540831.0,
    "metascore": 87.0,
    "runtime": 117,
    "cast_count": 74,
    "beige_color": "#c6a07d"
  },
  {
    "imdb_id": "tt6751668",
    "title": "Parasite",
    "beige_score": 14.9326,
    "release_year": 2019,
    "imdb_user_rating": 8.5,
    "imdb_user_ranking": 35,
    "budget_amount": 11400000.0,
    "gross_amount": 263136741.0,
    "metascore": 96.0,
    "runtime": 132,
    "cast_count": 89,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt7286456",
    "title": "Joker",
    "beige_score": 16.1129,
    "release_year": 2019,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 71,
    "budget_amount": 55000000.0,
    "gross_amount": 1074445730.0,
    "metascore": 59.0,
    "runtime": 122,
    "cast_count": 62,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt8579674",
    "title": "1917",
    "beige_score": 12.6226,
    "release_year": 2019,
    "imdb_user_rating": 8.2,
    "imdb_user_ranking": 124,
    "budget_amount": 95000000.0,
    "gross_amount": 384919389.0,
    "metascore": 78.0,
    "runtime": 119,
    "cast_count": 53,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt1950186",
    "title": "Ford v Ferrari",
    "beige_score": 12.443,
    "release_year": 2019,
    "imdb_user_rating": 8.1,
    "imdb_user_ranking": 213,
    "budget_amount": 97600000.0,
    "gross_amount": 225508210.0,
    "metascore": 81.0,
    "runtime": 152,
    "cast_count": 79,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt4154796",
    "title": "Avengers: Endgame",
    "beige_score": 14.5978,
    "release_year": 2019,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 81,
    "budget_amount": 356000000.0,
    "gross_amount": 2797501328.0,
    "metascore": 78.0,
    "runtime": 181,
    "cast_count": 104,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt8503618",
    "title": "Hamilton",
    "beige_score": 22.0714,
    "release_year": 2020,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 114,
    "budget_amount": null,
    "gross_amount": null,
    "metascore": 90.0,
    "runtime": 160,
    "cast_count": 21,
    "beige_color": "#a27753"
  },
  {
    "imdb_id": "tt10272386",
    "title": "The Father",
    "beige_score": 13.5,
    "release_year": 2020,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 132,
    "budget_amount": null,
    "gross_amount": 24662624.0,
    "metascore": 88.0,
    "runtime": 97,
    "cast_count": 8,
    "beige_color": "#f5e3cb"
  },
  {
    "imdb_id": "tt10872600",
    "title": "Spider-Man: No Way Home",
    "beige_score": 17.3889,
    "release_year": 2021,
    "imdb_user_rating": 8.3,
    "imdb_user_ranking": 116,
    "budget_amount": 200000000.0,
    "gross_amount": 1892747988.0,
    "metascore": 71.0,
    "runtime": 148,
    "cast_count": 54,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt15097216",
    "title": "Jai Bhim",
    "beige_score": 24.0,
    "release_year": 2021,
    "imdb_user_rating": 8.9,
    "imdb_user_ranking": 247,
    "budget_amount": null,
    "gross_amount": null,
    "metascore": null,
    "runtime": 164,
    "cast_count": 127,
    "beige_color": "#645246"
  },
  {
    "imdb_id": "tt6710474",
    "title": "Everything Everywhere All at Once",
    "beige_score": 14.5588,
    "release_year": 2022,
    "imdb_user_rating": 8.4,
    "imdb_user_ranking": 99,
    "budget_amount": null,
    "gross_amount": 68701914.0,
    "metascore": 81.0,
    "runtime": 139,
    "cast_count": 34,
    "beige_color": "#e1c8aa"
  },
  {
    "imdb_id": "tt1745960",
    "title": "Top Gun: Maverick",
    "beige_score": 17.3,
    "release_year": 2022,
    "imdb_user_rating": 8.7,
    "imdb_user_ranking": 52,
    "budget_amount": null,
    "gross_amount": 321445421.0,
    "metascore": 78.0,
    "runtime": 130,
    "cast_count": 40,
    "beige_color": "#e1c8aa"
  }
]