import React, { Component, Suspense } from 'react';
import CastContainer from '../components/castcontainer';
import BeigeMeter from '../components/beigemeter';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import { ButtonGroup, Tooltip } from '@mui/material';
import MediaQuery from 'react-responsive';
import { withRouter } from '../hooks/withrouter';
import { Helmet } from 'react-helmet-async';

import './thewhitewash.scss';

import { whitewashData } from '../data/whitewashdata';

const film = whitewashData;

class TheWhitewash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beige_name: {
        1: 'CREAMY',
        2: 'BEIGE FEVER',
        3: 'MIDDLEBEIGE',
        4: 'DOWN TO BROWN',
        5: 'WELCOME TO BEIGEWATCH',
      },
      shareText: 'SHARE',
    };
  }

  synopsis() {
    return (
      <React.Fragment>
        <p>
          From the less-talented co-creator of The Beige Index (ed note. Hey,
          fuck <em>you</em>!): <em>The Whitewash</em>.
        </p>
        <p>
          Siang Lu's searing debut is a black comedy about the whitewashing of
          the Asian film industry, told in the form of an oral documentary
        </p>
        <p>
          It sounded like a good idea at the time- A Hollywood spy thriller,
          starring, for the first time in history, an Asian male lead. With an
          estimated $350 million production budget and up-and-coming Hong Kong
          actor JK Jr, who, let's be honest, is not the sharpest tool in the
          shed, but probably the hottest, Brood Empire was basically a sure
          thing. Until it wasn't.
        </p>
        <p>
          So how did it all fall apart? There were smart guys involved. So
          smart, so woke. So woke it hurts. There was topnotch talent across the
          board and the financial backing of a heavyweight Chinese studio. And
          yet, Brood Empire is remembered now not as a historical landmark of
          Asian representation that smashed the bamboo ceiling in Hollywood, but
          rather as a fiasco of seismic proportions.
        </p>
        <p>
          The Whitewash is the definitive oral history of the whole sordid mess.
          Unofficial. Unasked for. Only intermittently fact-checked, and
          featuring a fool's gallery of actors, producers, directors, film
          historians and scummy click-bait journalists, to answer the question
          of how it all went so horribly, horribly wrong.
        </p>
      </React.Fragment>
    );
  }

  componentDidUpdate = () => {
    const animateEl = document.getElementById('dial-animation');
    animateEl.beginElement();
  };

  renderDesktop() {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {film.title}&nbsp;&nbsp;
            </h1>
            <p className="inline-text director-text">
              By <span itemProp={'director'}>{film.directors}</span> (
              <span itemProp="copyrightYear">{film.release_year}</span>, UQP)
            </p>
          </span>
          <div>
            <Tooltip title="Click to copy link" placement="top">
              <Button
                variant="contained"
                disableElevation
                size="medium"
                aria-label="text button group"
                color="warning"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://thebeigeindex.com/film/${film.imdb_id}`
                  );
                }}
              >
                {this.state.shareText}&nbsp;
                <LinkIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="film-poster">
          <video width="100%" autoPlay loop muted>
            <source src={film.video} />
          </video>
          <div className="poster-buttons">
            <ButtonGroup
              aria-label="text button group"
              fullWidth={true}
              color="warning"
              variant="contained"
              disableElevation
            >
              <Button href={film.amazon} target="_blank" rel="noreferrer">
                <center>Buy a copy now</center>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          {this.synopsis()}
        </div>
        <div className="film-beige">
          <BeigeMeter
            beigeScore={film.beige_score}
            beigeAngle={film.beige_angle}
            beigeRotation={film.beige_angle_string}
          />
          <p id="beige-name">SCORE: {beige_name[2]}</p>
          <div className="cast-wrapper">
            <h5>Cast</h5>
            <CastContainer cast={film.cast} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMobile() {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {film.title}&nbsp;&nbsp;
            </h1>
          </span>
        </div>
        <div className="share-buttons">
          <Tooltip title="Click to copy link" placement="top">
            <Button
              variant="outlined"
              aria-label="text button group"
              color="warning"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://thebeigeindex.com/film/${film.imdb_id}`
                );
              }}
            >
              {this.state.shareText}&nbsp;
              <LinkIcon />
            </Button>
          </Tooltip>
        </div>
        <div className="film-poster">
          <video width="100%" autoPlay loop muted>
            <source src={film.video} />
          </video>
          <ButtonGroup
            aria-label="text button group"
            fullWidth={true}
            color="warning"
            variant="contained"
            disableElevation
          >
            <Button href={film.amazon} target="_blank" rel="noreferrer">
              <center>Buy a copy now</center>
            </Button>
          </ButtonGroup>
        </div>
        <div className="poster-buttons">
          <ButtonGroup
            aria-label="text button group"
            fullWidth={true}
            color="warning"
            variant="contained"
            disableElevation
          >
            <Button href={film.amazon} target="_blank" rel="noreferrer">
              <center>Buy a copy now</center>
            </Button>
          </ButtonGroup>
        </div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          <Suspense>{this.synopsis()}</Suspense>
          <p className="inline-text director-text">
            {film.title}, written by{' '}
            <span itemProp={'director'}>{film.directors}</span> (
            <span itemProp="copyrightYear">{film.release_year}</span>) is not
            ranked on the IMDb Top 250. Trust us. We checked.
          </p>
        </div>
        <div className="film-beige">
          <Paper elevation={1} id="meter-paper">
            <div id="meter-wrapper">
              <BeigeMeter
                beigeScore={film.beige_score}
                beigeAngle={film.beige_angle}
                beigeRotation={film.beige_angle_string}
              />
              <p id="beige-name">SCORE: {beige_name[2]}</p>
            </div>
          </Paper>
        </div>
        <div className="cast-wrapper">
          <h5>Cast</h5>
          <CastContainer cast={film.cast} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>The Beige Index – {film.title}</title>
        </Helmet>
        <div className="film-info">
          <Paper elevation={3}>
            <div
              className="whitewash-info-grid"
              itemScope={true}
              itemType={'http://schema.org/Movie'}
            >
              <MediaQuery minWidth={1001}>{this.renderDesktop()}</MediaQuery>
              <MediaQuery maxWidth={1000}>{this.renderMobile()}</MediaQuery>
            </div>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TheWhitewash);
