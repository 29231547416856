import * as React from 'react';
import Box from '@mui/material/Box';
import { AlertTitle, Paper } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import MediaQuery from 'react-responsive';

import './topinfo.scss';

export default function TopInfo() {
  const [open, setOpen] = React.useState(true);

  return (
    <Paper
      sx={{ width: 'clamp(350px, 90%, 100%)', padding: '0', margin: 'auto' }}
    >
      <Box sx={{ width: 'clamp(350px, 100%, 100%)', margin: 'auto' }}>
        <Collapse in={open}>
          <Alert
            severity="info"
            variant="filled"
            className={'alertWrapper'}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle className={'alertWrapperTitle'}>
              How diverse are your favourite film casts?
            </AlertTitle>
            <MediaQuery maxWidth={1000}>
              The Beige Index has the answer. We've picked a random title to get
              you started, and you can tap and scroll the full shelf below, or
              use the navigation buttons to explore.
            </MediaQuery>
            <MediaQuery minWidth={1001}>
              <div className={'alertWrapperText'}>
                The Beige Index has the answer. We've picked a random title to
                get you started, and you can check out the full shelf below,
                using the mouse or navigation buttons to explore.
              </div>
            </MediaQuery>
          </Alert>
        </Collapse>
      </Box>
    </Paper>
  );
}
