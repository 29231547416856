import React from 'react';
import './presslist.scss';

import { useMediaQuery } from 'react-responsive';

import { pressLogos } from '../data/press';

export default function PressList() {
  const isPhone = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const logoWidth = isPhone ? 50 : 150;

  return (
    <React.Fragment>
      <div className="press-outer">
        <center>
          <h4>AS FEATURED IN:</h4>
        </center>
        <div className="press-container">
          {pressLogos.map((logo, index) => {
            return (
              <div key={index} className="press-wrapper">
                <a href={logo.link} target="_blank" rel="noreferrer">
                  <img
                    src={logo.logo}
                    alt={logo.name}
                    className="press-image"
                    width={logoWidth}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
