export const chartJSData = {
  datasets: [
    {
      label: 'The Shawshank Redemption',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0111161',
      data: [
        {
          x: 1,
          y: 11.4545,
        },
      ],
    },
    {
      label: 'The Godfather',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0068646',
      data: [
        {
          x: 2,
          y: 10,
        },
      ],
    },
    {
      label: 'The Dark Knight',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0468569',
      data: [
        {
          x: 3,
          y: 13.5567,
        },
      ],
    },
    {
      label: 'The Godfather: Part II',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0071562',
      data: [
        {
          x: 4,
          y: 10.5556,
        },
      ],
    },
    {
      label: '12 Angry Men',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0050083',
      data: [
        {
          x: 5,
          y: 10,
        },
      ],
    },
    {
      label: "Schindler's List",
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0108052',
      data: [
        {
          x: 6,
          y: 10.056,
        },
      ],
    },
    {
      label: 'The Lord of the Rings: The Return of the King',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0167260',
      data: [
        {
          x: 7,
          y: 10.8276,
        },
      ],
    },
    {
      label: 'Pulp Fiction',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0110912',
      data: [
        {
          x: 8,
          y: 13.1522,
        },
      ],
    },
    {
      label: 'The Lord of the Rings: The Fellowship of the Ring',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0120737',
      data: [
        {
          x: 9,
          y: 11.0714,
        },
      ],
    },
    {
      label: 'The Good, the Bad and the Ugly',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0060196',
      data: [
        {
          x: 10,
          y: 10.9524,
        },
      ],
    },
    {
      label: 'Forrest Gump',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0109830',
      data: [
        {
          x: 11,
          y: 13.9828,
        },
      ],
    },
    {
      label: 'Fight Club',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0137523',
      data: [
        {
          x: 12,
          y: 14.8113,
        },
      ],
    },
    {
      label: 'Inception',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1375666',
      data: [
        {
          x: 13,
          y: 11.8431,
        },
      ],
    },
    {
      label: 'The Lord of the Rings: The Two Towers',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0167261',
      data: [
        {
          x: 14,
          y: 10.96,
        },
      ],
    },
    {
      label: 'Star Wars: Episode V - The Empire Strikes Back',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0080684',
      data: [
        {
          x: 15,
          y: 10.9091,
        },
      ],
    },
    {
      label: 'The Matrix',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0133093',
      data: [
        {
          x: 16,
          y: 14.8056,
        },
      ],
    },
    {
      label: 'Goodfellas',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0099685',
      data: [
        {
          x: 17,
          y: 10.438,
        },
      ],
    },
    {
      label: "One Flew Over the Cuckoo's Nest",
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0073486',
      data: [
        {
          x: 18,
          y: 12.9688,
        },
      ],
    },
    {
      label: 'Se7en',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0114369',
      data: [
        {
          x: 19,
          y: 15.7273,
        },
      ],
    },
    {
      label: 'Seven Samurai',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0047478',
      data: [
        {
          x: 20,
          y: 15,
        },
      ],
    },
    {
      label: "It's a Wonderful Life",
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0038650',
      data: [
        {
          x: 21,
          y: 10.7692,
        },
      ],
    },
    {
      label: 'The Silence of the Lambs',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0102926',
      data: [
        {
          x: 22,
          y: 13.0189,
        },
      ],
    },
    {
      label: 'City of God',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0317248',
      data: [
        {
          x: 23,
          y: 21.2162,
        },
      ],
    },
    {
      label: 'Saving Private Ryan',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0120815',
      data: [
        {
          x: 24,
          y: 10.1176,
        },
      ],
    },
    {
      label: 'Life Is Beautiful',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0118799',
      data: [
        {
          x: 25,
          y: 10,
        },
      ],
    },
    {
      label: 'The Green Mile',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0120689',
      data: [
        {
          x: 26,
          y: 11.6111,
        },
      ],
    },
    {
      label: 'Interstellar',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0816692',
      data: [
        {
          x: 27,
          y: 11.6552,
        },
      ],
    },
    {
      label: 'Star Wars',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0076759',
      data: [
        {
          x: 28,
          y: 10.5714,
        },
      ],
    },
    {
      label: 'Terminator 2: Judgment Day',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0103064',
      data: [
        {
          x: 29,
          y: 13.1,
        },
      ],
    },
    {
      label: 'Back to the Future',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0088763',
      data: [
        {
          x: 30,
          y: 13.0909,
        },
      ],
    },
    {
      label: 'Spirited Away',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0245429',
      data: [
        {
          x: 31,
          y: 14.898,
        },
      ],
    },
    {
      label: 'Psycho',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0054215',
      data: [
        {
          x: 32,
          y: 10,
        },
      ],
    },
    {
      label: 'The Pianist',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0253474',
      data: [
        {
          x: 33,
          y: 10,
        },
      ],
    },
    {
      label: 'Léon: The Professional',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0110413',
      data: [
        {
          x: 34,
          y: 13.6833,
        },
      ],
    },
    {
      label: 'Parasite',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt6751668',
      data: [
        {
          x: 35,
          y: 14.8876,
        },
      ],
    },
    {
      label: 'The Lion King',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0110357',
      data: [
        {
          x: 36,
          y: 14.7619,
        },
      ],
    },
    {
      label: 'Gladiator',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0172495',
      data: [
        {
          x: 37,
          y: 11.7778,
        },
      ],
    },
    {
      label: 'American History X',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0120586',
      data: [
        {
          x: 38,
          y: 14.6341,
        },
      ],
    },
    {
      label: 'The Usual Suspects',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0114814',
      data: [
        {
          x: 39,
          y: 11.4839,
        },
      ],
    },
    {
      label: 'The Departed',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0407887',
      data: [
        {
          x: 40,
          y: 11.381,
        },
      ],
    },
    {
      label: 'The Prestige',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0482571',
      data: [
        {
          x: 41,
          y: 10.1111,
        },
      ],
    },
    {
      label: 'Casablanca',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0034583',
      data: [
        {
          x: 42,
          y: 11.3889,
        },
      ],
    },
    {
      label: 'Whiplash',
      backgroundColor: '#c6a07d',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2582802',
      data: [
        {
          x: 43,
          y: 18.7027,
        },
      ],
    },
    {
      label: 'The Intouchables',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1675434',
      data: [
        {
          x: 44,
          y: 12.6579,
        },
      ],
    },
    {
      label: 'Modern Times',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0027977',
      data: [
        {
          x: 45,
          y: 10.5,
        },
      ],
    },
    {
      label: 'Hara-Kiri',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0056058',
      data: [
        {
          x: 46,
          y: 15,
        },
      ],
    },
    {
      label: 'Once Upon a Time in the West',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0064116',
      data: [
        {
          x: 47,
          y: 11.7647,
        },
      ],
    },
    {
      label: 'Grave of the Fireflies',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0095327',
      data: [
        {
          x: 48,
          y: 15,
        },
      ],
    },
    {
      label: 'Rear Window',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0047396',
      data: [
        {
          x: 49,
          y: 10.8,
        },
      ],
    },
    {
      label: 'Alien',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0078748',
      data: [
        {
          x: 50,
          y: 14.4444,
        },
      ],
    },
    {
      label: 'City Lights',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0021749',
      data: [
        {
          x: 51,
          y: 11.4286,
        },
      ],
    },
    {
      label: 'Top Gun: Maverick',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1745960',
      data: [
        {
          x: 52,
          y: 16.15,
        },
      ],
    },
    {
      label: 'Cinema Paradiso',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0095765',
      data: [
        {
          x: 53,
          y: 10,
        },
      ],
    },
    {
      label: 'Memento',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0209144',
      data: [
        {
          x: 54,
          y: 10,
        },
      ],
    },
    {
      label: 'Apocalypse Now',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0078788',
      data: [
        {
          x: 55,
          y: 12.0833,
        },
      ],
    },
    {
      label: 'Indiana Jones and the Raiders of the Lost Ark',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0082971',
      data: [
        {
          x: 56,
          y: 13.1622,
        },
      ],
    },
    {
      label: 'Django Unchained',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1853728',
      data: [
        {
          x: 57,
          y: 16.4634,
        },
      ],
    },
    {
      label: 'WALL·E',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0910970',
      data: [
        {
          x: 58,
          y: 12.1364,
        },
      ],
    },
    {
      label: 'The Lives of Others',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0405094',
      data: [
        {
          x: 59,
          y: 10.5556,
        },
      ],
    },
    {
      label: 'Sunset Blvd.',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0043014',
      data: [
        {
          x: 60,
          y: 10,
        },
      ],
    },
    {
      label: 'Paths of Glory',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0050825',
      data: [
        {
          x: 61,
          y: 10.7059,
        },
      ],
    },
    {
      label: 'The Shining',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0081505',
      data: [
        {
          x: 62,
          y: 11.8182,
        },
      ],
    },
    {
      label: 'The Great Dictator',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0032553',
      data: [
        {
          x: 63,
          y: 10,
        },
      ],
    },
    {
      label: 'Witness for the Prosecution',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0051201',
      data: [
        {
          x: 64,
          y: 10,
        },
      ],
    },
    {
      label: 'Avengers: Infinity War',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt4154756',
      data: [
        {
          x: 65,
          y: 15.5702,
        },
      ],
    },
    {
      label: 'Aliens',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0090605',
      data: [
        {
          x: 66,
          y: 12.3077,
        },
      ],
    },
    {
      label: 'American Beauty',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0169547',
      data: [
        {
          x: 67,
          y: 11.7812,
        },
      ],
    },
    {
      label:
        'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0057012',
      data: [
        {
          x: 68,
          y: 11,
        },
      ],
    },
    {
      label: 'The Dark Knight Rises',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1345836',
      data: [
        {
          x: 69,
          y: 12.9324,
        },
      ],
    },
    {
      label: 'Spider-Man: Into the Spider-Verse',
      backgroundColor: '#c6a07d',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt4633694',
      data: [
        {
          x: 70,
          y: 18.4662,
        },
      ],
    },
    {
      label: 'Joker',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt7286456',
      data: [
        {
          x: 71,
          y: 14.7419,
        },
      ],
    },
    {
      label: 'Oldboy',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0364569',
      data: [
        {
          x: 72,
          y: 15,
        },
      ],
    },
    {
      label: 'Braveheart',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0112573',
      data: [
        {
          x: 73,
          y: 10,
        },
      ],
    },
    {
      label: 'Amadeus',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0086879',
      data: [
        {
          x: 74,
          y: 10,
        },
      ],
    },
    {
      label: 'Toy Story',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0114709',
      data: [
        {
          x: 75,
          y: 10,
        },
      ],
    },
    {
      label: 'Coco',
      backgroundColor: '#c6a07d',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2380307',
      data: [
        {
          x: 76,
          y: 19.3478,
        },
      ],
    },
    {
      label: 'The Boat',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0082096',
      data: [
        {
          x: 77,
          y: 10,
        },
      ],
    },
    {
      label: 'Inglourious Basterds',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0361748',
      data: [
        {
          x: 78,
          y: 11.1,
        },
      ],
    },
    {
      label: 'Princess Mononoke',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0119698',
      data: [
        {
          x: 79,
          y: 15,
        },
      ],
    },
    {
      label: 'Once Upon a Time in America',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0087843',
      data: [
        {
          x: 80,
          y: 10,
        },
      ],
    },
    {
      label: 'Avengers: Endgame',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt4154796',
      data: [
        {
          x: 81,
          y: 13.0144,
        },
      ],
    },
    {
      label: 'Good Will Hunting',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0119217',
      data: [
        {
          x: 82,
          y: 11.475,
        },
      ],
    },
    {
      label: 'Requiem for a Dream',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0180093',
      data: [
        {
          x: 83,
          y: 14.25,
        },
      ],
    },
    {
      label: 'Toy Story 3',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0435761',
      data: [
        {
          x: 84,
          y: 11.0625,
        },
      ],
    },
    {
      label: "Singin' in the Rain",
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0045152',
      data: [
        {
          x: 85,
          y: 11.1111,
        },
      ],
    },
    {
      label: 'Your Name.',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt5311514',
      data: [
        {
          x: 86,
          y: 15,
        },
      ],
    },
    {
      label: '3 Idiots',
      backgroundColor: '#645246',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1187043',
      data: [
        {
          x: 87,
          y: 23.8133,
        },
      ],
    },
    {
      label: 'Star Wars: Episode VI - Return of the Jedi',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0086190',
      data: [
        {
          x: 88,
          y: 11.4667,
        },
      ],
    },
    {
      label: '2001: A Space Odyssey',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0062622',
      data: [
        {
          x: 89,
          y: 10,
        },
      ],
    },
    {
      label: 'Reservoir Dogs',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0105236',
      data: [
        {
          x: 90,
          y: 11.3043,
        },
      ],
    },
    {
      label: 'Eternal Sunshine of the Spotless Mind',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0338013',
      data: [
        {
          x: 91,
          y: 11.1111,
        },
      ],
    },
    {
      label: 'High and Low',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0057565',
      data: [
        {
          x: 92,
          y: 15,
        },
      ],
    },
    {
      label: 'Citizen Kane',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0033467',
      data: [
        {
          x: 93,
          y: 10,
        },
      ],
    },
    {
      label: 'Capernaum',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt8267604',
      data: [
        {
          x: 94,
          y: 22.1391,
        },
      ],
    },
    {
      label: 'Lawrence of Arabia',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0056172',
      data: [
        {
          x: 95,
          y: 13.5455,
        },
      ],
    },
    {
      label: 'The Hunt',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2106476',
      data: [
        {
          x: 96,
          y: 10,
        },
      ],
    },
    {
      label: 'M',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0022100',
      data: [
        {
          x: 97,
          y: 10,
        },
      ],
    },
    {
      label: 'North by Northwest',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0053125',
      data: [
        {
          x: 98,
          y: 10,
        },
      ],
    },
    {
      label: 'Everything Everywhere All at Once',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt6710474',
      data: [
        {
          x: 99,
          y: 13.6765,
        },
      ],
    },
    {
      label: 'Vertigo',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0052357',
      data: [
        {
          x: 100,
          y: 10,
        },
      ],
    },
    {
      label: 'Amélie',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0211915',
      data: [
        {
          x: 101,
          y: 10.3582,
        },
      ],
    },
    {
      label: 'A Clockwork Orange',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0066921',
      data: [
        {
          x: 102,
          y: 10,
        },
      ],
    },
    {
      label: 'Come and See',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0091251',
      data: [
        {
          x: 103,
          y: 10,
        },
      ],
    },
    {
      label: 'Full Metal Jacket',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0093058',
      data: [
        {
          x: 104,
          y: 16.5,
        },
      ],
    },
    {
      label: 'Double Indemnity',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0036775',
      data: [
        {
          x: 105,
          y: 10,
        },
      ],
    },
    {
      label: 'The Apartment',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0053604',
      data: [
        {
          x: 106,
          y: 10,
        },
      ],
    },
    {
      label: 'Scarface',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0086250',
      data: [
        {
          x: 107,
          y: 13.5625,
        },
      ],
    },
    {
      label: 'Ikiru',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0044741',
      data: [
        {
          x: 108,
          y: 15,
        },
      ],
    },
    {
      label: 'To Kill a Mockingbird',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0056592',
      data: [
        {
          x: 109,
          y: 13.3333,
        },
      ],
    },
    {
      label: 'The Sting',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0070735',
      data: [
        {
          x: 110,
          y: 12.5,
        },
      ],
    },
    {
      label: 'Taxi Driver',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0075314',
      data: [
        {
          x: 111,
          y: 12.2973,
        },
      ],
    },
    {
      label: 'L.A. Confidential',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0119488',
      data: [
        {
          x: 112,
          y: 11.4942,
        },
      ],
    },
    {
      label: 'Up',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1049413',
      data: [
        {
          x: 113,
          y: 11.6667,
        },
      ],
    },
    {
      label: 'Heat',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0113277',
      data: [
        {
          x: 115,
          y: 13.746,
        },
      ],
    },
    {
      label: 'Spider-Man: No Way Home',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt10872600',
      data: [
        {
          x: 116,
          y: 16.3704,
        },
      ],
    },
    {
      label: 'Metropolis',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0017136',
      data: [
        {
          x: 117,
          y: 10,
        },
      ],
    },
    {
      label: 'Snatch',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0208092',
      data: [
        {
          x: 118,
          y: 11.8421,
        },
      ],
    },
    {
      label: 'A Separation',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1832382',
      data: [
        {
          x: 119,
          y: 22,
        },
      ],
    },
    {
      label: 'Die Hard',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0095016',
      data: [
        {
          x: 120,
          y: 11.6949,
        },
      ],
    },
    {
      label: 'Incendies',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1255953',
      data: [
        {
          x: 121,
          y: 20.6098,
        },
      ],
    },
    {
      label: 'Indiana Jones and the Last Crusade',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0097576',
      data: [
        {
          x: 122,
          y: 11,
        },
      ],
    },
    {
      label: 'Bicycle Thieves',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0040522',
      data: [
        {
          x: 123,
          y: 10,
        },
      ],
    },
    {
      label: '1917',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt8579674',
      data: [
        {
          x: 124,
          y: 10.6981,
        },
      ],
    },
    {
      label: 'Like Stars on Earth',
      backgroundColor: '#645246',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0986264',
      data: [
        {
          x: 125,
          y: 24,
        },
      ],
    },
    {
      label: 'Downfall',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0363163',
      data: [
        {
          x: 126,
          y: 10,
        },
      ],
    },
    {
      label: 'For a Few Dollars More',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0059578',
      data: [
        {
          x: 127,
          y: 10,
        },
      ],
    },
    {
      label: 'Batman Begins',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0372784',
      data: [
        {
          x: 128,
          y: 13.4417,
        },
      ],
    },
    {
      label: 'Dangal',
      backgroundColor: '#645246',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt5074352',
      data: [
        {
          x: 129,
          y: 24.2222,
        },
      ],
    },
    {
      label: 'The Kid',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0012349',
      data: [
        {
          x: 130,
          y: 10,
        },
      ],
    },
    {
      label: 'Some Like It Hot',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0053291',
      data: [
        {
          x: 131,
          y: 10,
        },
      ],
    },
    {
      label: 'The Father',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt10272386',
      data: [
        {
          x: 132,
          y: 11.75,
        },
      ],
    },
    {
      label: 'All About Eve',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0042192',
      data: [
        {
          x: 133,
          y: 10,
        },
      ],
    },
    {
      label: 'Green Book',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt6966692',
      data: [
        {
          x: 134,
          y: 14.2615,
        },
      ],
    },
    {
      label: 'The Wolf of Wall Street',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0993846',
      data: [
        {
          x: 135,
          y: 11.5299,
        },
      ],
    },
    {
      label: 'Judgment at Nuremberg',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0055031',
      data: [
        {
          x: 136,
          y: 10,
        },
      ],
    },
    {
      label: 'Unforgiven',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0105695',
      data: [
        {
          x: 137,
          y: 11.2683,
        },
      ],
    },
    {
      label: 'Casino',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0112641',
      data: [
        {
          x: 138,
          y: 10.4265,
        },
      ],
    },
    {
      label: "Pan's Labyrinth",
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0457430',
      data: [
        {
          x: 139,
          y: 10.3226,
        },
      ],
    },
    {
      label: 'Ran',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0089881',
      data: [
        {
          x: 140,
          y: 15,
        },
      ],
    },
    {
      label: 'There Will Be Blood',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0469494',
      data: [
        {
          x: 141,
          y: 10,
        },
      ],
    },
    {
      label: 'The Sixth Sense',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0167404',
      data: [
        {
          x: 142,
          y: 12.1951,
        },
      ],
    },
    {
      label: 'A Beautiful Mind',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0268978',
      data: [
        {
          x: 143,
          y: 10,
        },
      ],
    },
    {
      label: 'Monty Python and the Holy Grail',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0071853',
      data: [
        {
          x: 144,
          y: 10.2714,
        },
      ],
    },
    {
      label: 'The Truman Show',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0120382',
      data: [
        {
          x: 145,
          y: 13.2754,
        },
      ],
    },
    {
      label: 'Yojimbo',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0055630',
      data: [
        {
          x: 146,
          y: 15,
        },
      ],
    },
    {
      label: 'The Treasure of the Sierra Madre',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0040897',
      data: [
        {
          x: 147,
          y: 14.5455,
        },
      ],
    },
    {
      label: 'Rashomon',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0042876',
      data: [
        {
          x: 148,
          y: 15,
        },
      ],
    },
    {
      label: 'The Great Escape',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0057115',
      data: [
        {
          x: 149,
          y: 10,
        },
      ],
    },
    {
      label: 'Shutter Island',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1130884',
      data: [
        {
          x: 150,
          y: 12.5385,
        },
      ],
    },
    {
      label: 'Jurassic Park',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0107290',
      data: [
        {
          x: 151,
          y: 12.9545,
        },
      ],
    },
    {
      label: 'Kill Bill: Vol. 1',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0266697',
      data: [
        {
          x: 152,
          y: 14.359,
        },
      ],
    },
    {
      label: 'No Country for Old Men',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0477348',
      data: [
        {
          x: 153,
          y: 12.1136,
        },
      ],
    },
    {
      label: 'The Elephant Man',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0080678',
      data: [
        {
          x: 154,
          y: 10.0877,
        },
      ],
    },
    {
      label: 'Finding Nemo',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0266543',
      data: [
        {
          x: 155,
          y: 10,
        },
      ],
    },
    {
      label: 'Raging Bull',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0081398',
      data: [
        {
          x: 156,
          y: 10.8989,
        },
      ],
    },
    {
      label: 'Chinatown',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0071315',
      data: [
        {
          x: 157,
          y: 10.6098,
        },
      ],
    },
    {
      label: 'Gone with the Wind',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0031381',
      data: [
        {
          x: 158,
          y: 12.1277,
        },
      ],
    },
    {
      label: 'V for Vendetta',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0434409',
      data: [
        {
          x: 159,
          y: 10.4267,
        },
      ],
    },
    {
      label: 'Inside Out',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2096673',
      data: [
        {
          x: 160,
          y: 12.4464,
        },
      ],
    },
    {
      label: 'The Thing',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0084787',
      data: [
        {
          x: 161,
          y: 11.3333,
        },
      ],
    },
    {
      label: 'Lock, Stock and Two Smoking Barrels',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0120735',
      data: [
        {
          x: 162,
          y: 12.093,
        },
      ],
    },
    {
      label: 'Dial M for Murder',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0046912',
      data: [
        {
          x: 163,
          y: 10,
        },
      ],
    },
    {
      label: 'The Secret in Their Eyes',
      backgroundColor: '#c6a07d',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1305806',
      data: [
        {
          x: 164,
          y: 19.6774,
        },
      ],
    },
    {
      label: 'The Bridge on the River Kwai',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0050212',
      data: [
        {
          x: 165,
          y: 13.3158,
        },
      ],
    },
    {
      label: "Howl's Moving Castle",
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0347149',
      data: [
        {
          x: 166,
          y: 14.0476,
        },
      ],
    },
    {
      label: 'Three Billboards Outside Ebbing, Missouri',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt5027774',
      data: [
        {
          x: 167,
          y: 14.5167,
        },
      ],
    },
    {
      label: 'Trainspotting',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0117951',
      data: [
        {
          x: 168,
          y: 10.7692,
        },
      ],
    },
    {
      label: 'Gran Torino',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1205489',
      data: [
        {
          x: 169,
          y: 14.7059,
        },
      ],
    },
    {
      label: 'Warrior',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1291584',
      data: [
        {
          x: 170,
          y: 14.2167,
        },
      ],
    },
    {
      label: 'Fargo',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0116282',
      data: [
        {
          x: 171,
          y: 11.7105,
        },
      ],
    },
    {
      label: 'My Neighbor Totoro',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0096283',
      data: [
        {
          x: 172,
          y: 14.5652,
        },
      ],
    },
    {
      label: 'Prisoners',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1392214',
      data: [
        {
          x: 173,
          y: 14.2647,
        },
      ],
    },
    {
      label: 'Million Dollar Baby',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0405159',
      data: [
        {
          x: 174,
          y: 13.093,
        },
      ],
    },
    {
      label: 'The Gold Rush',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0015864',
      data: [
        {
          x: 175,
          y: 10,
        },
      ],
    },
    {
      label: 'Blade Runner',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0083658',
      data: [
        {
          x: 176,
          y: 12.25,
        },
      ],
    },
    {
      label: 'Catch Me If You Can',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0264464',
      data: [
        {
          x: 177,
          y: 11.0098,
        },
      ],
    },
    {
      label: 'The Third Man',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0041959',
      data: [
        {
          x: 179,
          y: 10,
        },
      ],
    },
    {
      label: 'Children of Heaven',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0118849',
      data: [
        {
          x: 180,
          y: 22,
        },
      ],
    },
    {
      label: 'Ben-Hur',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0052618',
      data: [
        {
          x: 181,
          y: 10,
        },
      ],
    },
    {
      label: '12 Years a Slave',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2024544',
      data: [
        {
          x: 183,
          y: 20.0862,
        },
      ],
    },
    {
      label: 'Wild Strawberries',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0050986',
      data: [
        {
          x: 184,
          y: 10,
        },
      ],
    },
    {
      label: 'Gone Girl',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2267998',
      data: [
        {
          x: 185,
          y: 11.5116,
        },
      ],
    },
    {
      label: 'Before Sunrise',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0112471',
      data: [
        {
          x: 186,
          y: 10.7143,
        },
      ],
    },
    {
      label: 'The Deer Hunter',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0077416',
      data: [
        {
          x: 187,
          y: 14.0426,
        },
      ],
    },
    {
      label: 'Harry Potter and the Deathly Hallows: Part 2',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1201607',
      data: [
        {
          x: 188,
          y: 10.84,
        },
      ],
    },
    {
      label: 'In the Name of the Father',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0107207',
      data: [
        {
          x: 189,
          y: 10.3846,
        },
      ],
    },
    {
      label: 'The Grand Budapest Hotel',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2278388',
      data: [
        {
          x: 190,
          y: 10.4343,
        },
      ],
    },
    {
      label: 'Mr. Smith Goes to Washington',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0031679',
      data: [
        {
          x: 191,
          y: 10,
        },
      ],
    },
    {
      label: 'The Wages of Fear',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0046268',
      data: [
        {
          x: 192,
          y: 13.6154,
        },
      ],
    },
    {
      label: 'Barry Lyndon',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0072684',
      data: [
        {
          x: 194,
          y: 10,
        },
      ],
    },
    {
      label: 'Memories of Murder',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0353969',
      data: [
        {
          x: 195,
          y: 15,
        },
      ],
    },
    {
      label: 'Room',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt3170832',
      data: [
        {
          x: 196,
          y: 12.2381,
        },
      ],
    },
    {
      label: 'Hacksaw Ridge',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt2119532',
      data: [
        {
          x: 197,
          y: 11.0556,
        },
      ],
    },
    {
      label: 'The Seventh Seal',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0050976',
      data: [
        {
          x: 199,
          y: 10,
        },
      ],
    },
    {
      label: 'Wild Tales',
      backgroundColor: '#c6a07d',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt3011894',
      data: [
        {
          x: 200,
          y: 20,
        },
      ],
    },
    {
      label: 'The Big Lebowski',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0118715',
      data: [
        {
          x: 201,
          y: 13.175,
        },
      ],
    },
    {
      label: 'How to Train Your Dragon',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0892769',
      data: [
        {
          x: 202,
          y: 10.7692,
        },
      ],
    },
    {
      label: 'Mad Max: Fury Road',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1392190',
      data: [
        {
          x: 203,
          y: 11.1704,
        },
      ],
    },
    {
      label: 'Mary and Max',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0978762',
      data: [
        {
          x: 204,
          y: 11.6667,
        },
      ],
    },
    {
      label: 'Monsters, Inc.',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0198781',
      data: [
        {
          x: 205,
          y: 10.5,
        },
      ],
    },
    {
      label: 'Jaws',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0073195',
      data: [
        {
          x: 206,
          y: 10,
        },
      ],
    },
    {
      label: 'The Passion of Joan of Arc',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0019254',
      data: [
        {
          x: 207,
          y: 10,
        },
      ],
    },
    {
      label: 'Pather Panchali',
      backgroundColor: '#645246',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0048473',
      data: [
        {
          x: 208,
          y: 24,
        },
      ],
    },
    {
      label: 'Hotel Rwanda',
      backgroundColor: '#645246',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0395169',
      data: [
        {
          x: 209,
          y: 25.4054,
        },
      ],
    },
    {
      label: 'Tokyo Story',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0046438',
      data: [
        {
          x: 210,
          y: 15,
        },
      ],
    },
    {
      label: 'Dead Poets Society',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0097165',
      data: [
        {
          x: 211,
          y: 10,
        },
      ],
    },
    {
      label: 'Platoon',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0091763',
      data: [
        {
          x: 212,
          y: 15.3514,
        },
      ],
    },
    {
      label: 'Rocky',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0075148',
      data: [
        {
          x: 213,
          y: 14.75,
        },
      ],
    },
    {
      label: 'Ford v Ferrari',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1950186',
      data: [
        {
          x: 214,
          y: 10.5443,
        },
      ],
    },
    {
      label: 'Stand by Me',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0092005',
      data: [
        {
          x: 215,
          y: 10,
        },
      ],
    },
    {
      label: 'The Terminator',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0088247',
      data: [
        {
          x: 216,
          y: 12.2791,
        },
      ],
    },
    {
      label: 'Rush',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1979320',
      data: [
        {
          x: 217,
          y: 10.6761,
        },
      ],
    },
    {
      label: 'Spotlight',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1895587',
      data: [
        {
          x: 218,
          y: 11.306,
        },
      ],
    },
    {
      label: 'Into the Wild',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0758758',
      data: [
        {
          x: 219,
          y: 11.3889,
        },
      ],
    },
    {
      label: 'Network',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0074958',
      data: [
        {
          x: 220,
          y: 11.2,
        },
      ],
    },
    {
      label: 'The Wizard of Oz',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0032138',
      data: [
        {
          x: 221,
          y: 10,
        },
      ],
    },
    {
      label: 'Logan',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt3315342',
      data: [
        {
          x: 222,
          y: 14.8391,
        },
      ],
    },
    {
      label: 'Groundhog Day',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0107048',
      data: [
        {
          x: 223,
          y: 11.0417,
        },
      ],
    },
    {
      label: 'Ratatouille',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0382932',
      data: [
        {
          x: 224,
          y: 10.2273,
        },
      ],
    },
    {
      label: 'The Exorcist',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0070047',
      data: [
        {
          x: 225,
          y: 10,
        },
      ],
    },
    {
      label: 'Before Sunset',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0381681',
      data: [
        {
          x: 226,
          y: 10,
        },
      ],
    },
    {
      label: 'The Best Years of Our Lives',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0036868',
      data: [
        {
          x: 227,
          y: 10,
        },
      ],
    },
    {
      label: 'The Incredibles',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0317705',
      data: [
        {
          x: 228,
          y: 11.7333,
        },
      ],
    },
    {
      label: 'To Be or Not to Be',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0035446',
      data: [
        {
          x: 229,
          y: 10,
        },
      ],
    },
    {
      label: 'The Battle of Algiers',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0058946',
      data: [
        {
          x: 230,
          y: 20.2857,
        },
      ],
    },
    {
      label: 'Rebecca',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0032976',
      data: [
        {
          x: 231,
          y: 10,
        },
      ],
    },
    {
      label: 'The Grapes of Wrath',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0032551',
      data: [
        {
          x: 232,
          y: 10,
        },
      ],
    },
    {
      label: "Hachi: A Dog's Tale",
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1028532',
      data: [
        {
          x: 233,
          y: 11.087,
        },
      ],
    },
    {
      label: 'Cool Hand Luke',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0061512',
      data: [
        {
          x: 234,
          y: 10,
        },
      ],
    },
    {
      label: 'Amores perros',
      backgroundColor: '#c6a07d',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0245712',
      data: [
        {
          x: 235,
          y: 19.4889,
        },
      ],
    },
    {
      label: 'Pirates of the Caribbean: The Curse of the Black Pearl',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0325980',
      data: [
        {
          x: 236,
          y: 13.1406,
        },
      ],
    },
    {
      label: 'The 400 Blows',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0053198',
      data: [
        {
          x: 237,
          y: 10,
        },
      ],
    },
    {
      label: 'Dersu Uzala',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0071411',
      data: [
        {
          x: 238,
          y: 10.8276,
        },
      ],
    },
    {
      label: 'La Haine',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0113247',
      data: [
        {
          x: 239,
          y: 15.22,
        },
      ],
    },
    {
      label: 'Persona',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0060827',
      data: [
        {
          x: 240,
          y: 10,
        },
      ],
    },
    {
      label: 'My Father and My Son',
      backgroundColor: '#a27753',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0476735',
      data: [
        {
          x: 241,
          y: 22,
        },
      ],
    },
    {
      label: 'Life of Brian',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0079470',
      data: [
        {
          x: 242,
          y: 10,
        },
      ],
    },
    {
      label: 'The Sound of Music',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0059742',
      data: [
        {
          x: 243,
          y: 10,
        },
      ],
    },
    {
      label: 'It Happened One Night',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0025316',
      data: [
        {
          x: 244,
          y: 10,
        },
      ],
    },
    {
      label: 'The Handmaiden',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt4016934',
      data: [
        {
          x: 245,
          y: 14.9091,
        },
      ],
    },
    {
      label: 'Aladdin',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0103639',
      data: [
        {
          x: 246,
          y: 10,
        },
      ],
    },
    {
      label: 'Gandhi',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0083987',
      data: [
        {
          x: 247,
          y: 17.3767,
        },
      ],
    },
    {
      label: 'The Help',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1454029',
      data: [
        {
          x: 248,
          y: 16.7925,
        },
      ],
    },
    {
      label: 'Beauty and the Beast',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0101414',
      data: [
        {
          x: 249,
          y: 10.7692,
        },
      ],
    },
    {
      label: 'Donnie Darko',
      backgroundColor: '#f5e3cb',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt0246578',
      data: [
        {
          x: 256,
          y: 10,
        },
      ],
    },
    {
      label: 'Dune',
      backgroundColor: '#e1c8aa',
      borderColor: 'rgba(245, 245, 245, 0.25)',
      imdb_id: 'tt1160419',
      data: [
        {
          x: 261,
          y: 16.1111,
        },
      ],
    },
  ],
};
