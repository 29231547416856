import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './filmaccordion.scss';

class MoreInfoAccordion extends Component {
  state = {};
  render() {
    const { film } = this.props;
    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4>Film Details</h4>
          </AccordionSummary>
          <AccordionDetails>
            <table className="details-table">
              <tbody>
                <tr>
                  <td>
                    <h5>Budget</h5>
                    <p>{film.budget_string}</p>
                  </td>
                  <td>
                    <h5>Gross</h5>
                    <p>{film.gross_string}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5>IMDb User Score</h5>
                    <p>{film.imdb_user_rating}</p>
                  </td>
                  <td>
                    <h5>Metacritic</h5>
                    <p>{film.metascore_string}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Synopsis</h5>
            <p>{film.plot}</p>
            <h5>Major Wins and Nominations</h5>
            <p>
              {film.other_wins_count} wins and an additional{' '}
              {film.other_noms_count} nominations (approximate).
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default MoreInfoAccordion;
