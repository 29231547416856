import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { ButtonGroup } from '@mui/material';
import MuiSelect from './muiselect';
import MediaQuery from 'react-responsive';

import './muiselect.scss';

class TopMenu extends Component {
  state = {};

  xMenu() {
    const { selectedView, selectedField, sortOptions, setChartX } = this.props;
    if (selectedView !== 'table') {
      return (
        <React.Fragment>
          <span>
            <h2>sorted by&nbsp;</h2>
          </span>
          <span>
            <MuiSelect
              sortOptions={sortOptions}
              selectedOption={selectedField}
              selectFunction={setChartX}
              // label={'x-axis'}
            />
          </span>
        </React.Fragment>
      );
    } else return null;
  }

  yMenu() {
    const { selectedView, selectedField1, sortOptions, setChartY } = this.props;
    if (selectedView === 'chart') {
      return (
        <React.Fragment>
          <span>
            <h2>&nbsp;and&nbsp;</h2>
          </span>
          <span>
            <MuiSelect
              sortOptions={sortOptions}
              selectedOption={selectedField1}
              selectFunction={setChartY}
              // label={'y-axis'}
            />
          </span>
        </React.Fragment>
      );
    }
  }

  controls() {
    if (this.props.selectedView === 'shelf') {
      const { selectRandom, selectLeft, selectRight } = this.props;
      return (
        <div className="controls">
          <ButtonGroup color="warning" fullWidth={true}>
            <Button
              onClick={() => {
                selectLeft();
              }}
            >
              <ArrowBackIosNewIcon />
            </Button>
            <Button
              onClick={() => {
                selectRandom();
              }}
            >
              <ShuffleIcon />
            </Button>
            <Button
              onClick={() => {
                selectRight();
              }}
            >
              <ArrowForwardIosIcon />
            </Button>
          </ButtonGroup>
        </div>
      );
    }
  }

  render() {
    const { viewOptions, selectedView, selectView } = this.props;
    return (
      <React.Fragment>
        <div className="shelf-menu-wrapper">
          <div className="shelf-menu">
            <div>
              <span>
                <h2>IMDb Top 250&nbsp;</h2>
              </span>
              <MediaQuery minWidth={1000}>
                <span>
                  <h2>in&nbsp;</h2>
                </span>
                <span>
                  <MuiSelect
                    sortOptions={viewOptions}
                    selectedOption={selectedView}
                    selectFunction={selectView}
                  />
                </span>
              </MediaQuery>
              {this.xMenu()}
              {this.yMenu()}
              <span>
                <h2>.</h2>
              </span>
            </div>
          </div>
        </div>
        {this.controls()}
      </React.Fragment>
    );
  }
}

export default TopMenu;
