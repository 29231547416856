import { Tooltip } from '@mui/material';
import React, { Component } from 'react';

import './cast.scss';

class CastBox extends Component {
  state = {
    cast_color: {
      1: '#f5e3cb',
      2: '#e1c8aa',
      3: '#c6a07d',
      4: '#a27753',
      5: '#645246',
      1000: '#FF0000',
    },
  };
  render() {
    const { castMember } = this.props;
    const { cast_color } = this.state;
    return (
      <React.Fragment>
        <Tooltip title={castMember[1]} placement="top-start">
          <div
            className="cast-member"
            style={{
              backgroundColor: cast_color[castMember[0]],
            }}
          >
            <span> </span>
          </div>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default CastBox;
