export const sortData = [
  { value: 'beige_score', label: 'beige score' },
  { value: 'release_year', label: 'release year' },
  { value: 'title', label: 'title (A–Z)' }, // alphabetical
  { value: 'imdb_user_ranking', label: 'user ranking (IMDb)' },
  { value: 'budget_amount', label: 'budget' },
  { value: 'gross_amount', label: 'revenue' },
  { value: 'metascore', label: 'Metacritic score' },
  { value: 'imdb_user_rating', label: 'user rating (IMDb)' },
  { value: 'runtime', label: 'runtime' },
  { value: 'cast_count', label: 'cast size' },
];
