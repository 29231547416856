import React, { Component, Suspense } from 'react';
import CastContainer from '../components/castcontainer';
import BeigeMeter from '../components/beigemeter';
import MoreInfoAccordion from '../components/filmaccordion';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { ButtonGroup, Snackbar, Tooltip } from '@mui/material';
import MediaQuery from 'react-responsive';
import { withRouter } from '../hooks/withrouter';
import { Helmet } from 'react-helmet-async';
import { filmInfo } from '../data/filminfo';
import { filmSort } from '../data/filmsort';
import { lazy } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './filminfo.scss';
import ErrorPage from './errorpage';
import { AddToDrive } from '@mui/icons-material';

const Assessment = lazy(() => import('./assessment'));

class FilmInfoRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beige_name: {
        1: 'CREAMY',
        2: 'BEIGE FEVER',
        3: 'MIDDLEBEIGE',
        4: 'DOWN TO BROWN',
        5: 'WELCOME TO BEIGEWATCH',
      },
      shareText: 'SHARE',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.selectedFilmID !== this.props.router.params.id)
      this.props.selectFilm(this.props.router.params.id);
  }

  getFilm = () => {
    let data = filmInfo.find(
      ({ imdb_id }) => imdb_id === this.props.router.params.id
    );

    let data2 = filmSort.find(
      ({ imdb_id }) => imdb_id === this.props.router.params.id
    );

    if (data) {
      let returnData = {
        ...data,
        ...data2,
      };

      return returnData;
    } else {
      return '404';
    }
  };

  controls() {
    const { selectRandom, selectLeft, selectRight } = this.props;

    const tableText = (
      <table>
        <tbody>
          <tr>
            <td>Left arrow:</td>
            <td>Previous</td>
          </tr>
          <tr>
            <td sx={{ marginRight: '5px' }}>Right arrow:</td>
            <td>Next</td>
          </tr>
          <tr>
            <td>/ key:</td>
            <td>Random</td>
          </tr>
        </tbody>
      </table>
    );

    return (
      <div>
        <div className="bottom-controls">
          <ButtonGroup variant="outlined" color="warning" fullWidth={true}>
            <Button
              onClick={() => {
                selectLeft();
              }}
            >
              <ArrowBackIosNewIcon />
            </Button>
            <Button
              onClick={() => {
                selectRandom();
              }}
            >
              <ShuffleIcon />
            </Button>
            <Button
              onClick={() => {
                selectRight();
              }}
            >
              <ArrowForwardIosIcon />
            </Button>
          </ButtonGroup>
        </div>
        <center>
          <p className="bottom-controls-text">
            Keyboard Controls{' '}
            <Tooltip placement="right-start" title={tableText}>
              <InfoOutlinedIcon />
            </Tooltip>
          </p>
        </center>
      </div>
    );
  }

  // Render functions for duplicate mobile/desktop code

  shareButton(film, view) {
    return (
      <div className="share-buttons">
        <Button
          variant="contained"
          disableElevation
          aria-label="text button group"
          color="warning"
          size="medium"
          onClick={() => {
            if (navigator.share && view === 'mobile') {
              navigator.share({
                title: `The Beige Index – ${film.title}`,
                text: `Check out ${film.title} on The Beige Index.`,
                url: `https://thebeigeindex.com/film/${film.imdb_id}`,
              });
            } else {
              navigator.clipboard.writeText(
                `https://thebeigeindex.com/film/${film.imdb_id}`
              );
              this.setState({ snackbarOpen: true });
            }
          }}
        >
          {this.state.shareText}&nbsp;
          <LinkIcon />
        </Button>
      </div>
    );
  }

  poster(film) {
    return (
      <img
        itemProp="image"
        src={`https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/posters/poster_${film.imdb_id}.jpg`}
        width="100%"
        alt={film.title}
      />
    );
  }

  assessment(film) {
    return (
      <Suspense>
        <Assessment id={film.imdb_id} selectFilm={this.props.selectFilm} />
      </Suspense>
    );
  }

  posterButtons(film) {
    return (
      <div className="poster-buttons">
        <ButtonGroup
          variant="outlined"
          aria-label="text button group"
          fullWidth={true}
          color="warning"
        >
          <Button href={film.amazon} target="_blank" rel="sponsored">
            <center>Buy it</center>
          </Button>
          <Button href={film.justwatch} target="_blank" rel="noreferrer">
            <center>Stream it</center>
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  getRotationString = (beige_angle) => {
    if (beige_angle) {
      const beigeRotation = beige_angle.toString();
      const rotationFinish = beigeRotation.concat(' 111.63 111.37');

      return rotationFinish;
    } else return '0 111.63 111.37';
  };

  componentDidUpdate = () => {
    if (this.props.selectedFilmID !== this.props.router.params.id)
      this.props.selectFilm(this.props.router.params.id);
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  renderDesktop = (film, rotationString) => {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {film.title}&nbsp;&nbsp;
            </h1>
            <p className="inline-text director-text">
              Dir. <span itemProp={'director'}>{film.directors}</span> (
              <span itemProp="copyrightYear">{film.release_year}</span>,
              {' IMDb #'}
              {film.imdb_user_ranking})
            </p>
          </span>
          {this.shareButton(film, 'desktop')}
        </div>
        <div className="film-poster">
          {this.poster(film)}
          {this.posterButtons(film)}
        </div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          {this.assessment(film)}
        </div>
        <div className="film-beige">
          <BeigeMeter
            beigeScore={film.beige_score}
            beigeAngle={film.beige_angle}
            beigeRotation={rotationString}
          />
          <center>
            <h4 id="beige-name">SCORE: {beige_name[film.beige_index]}</h4>
            <hr />
          </center>
          <div className="cast-wrapper">
            <h5>
              Cast{' '}
              <Tooltip
                placement="top"
                title="Hover over squares to view cast members."
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </h5>
            <CastContainer cast={film.cast} />
          </div>
        </div>
        <div className="more-info">
          <MoreInfoAccordion film={film} />
        </div>
      </React.Fragment>
    );
  };

  renderMobile = (film, rotationString) => {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {film.title}&nbsp;&nbsp;
            </h1>
          </span>
          {this.shareButton(film, 'mobile')}
        </div>
        {this.shareButton(film, 'mobile')}
        <div className="film-poster">
          <h5>Poster</h5>
          {this.poster(film)}
        </div>
        {this.posterButtons(film)}
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          {this.assessment(film)}
          <hr />
          <p className="inline-text director-text">
            {film.title}, directed by{' '}
            <span itemProp={'director'}>{film.directors}</span> (
            <span itemProp="copyrightYear">{film.release_year}</span>) is ranked
            #{film.imdb_user_ranking} on the IMDb Top 250.
          </p>
        </div>
        <div className="film-beige">
          <Paper elevation={1} id="meter-paper">
            <div id="meter-wrapper">
              <BeigeMeter
                beigeScore={film.beige_score}
                beigeAngle={film.beige_angle}
                beigeRotation={rotationString}
              />
              <p id="beige-name">SCORE: {beige_name[film.beige_index]}</p>
            </div>
          </Paper>
        </div>
        <div className="cast-wrapper">
          <h5>Cast</h5>
          <CastContainer cast={film.cast} />
        </div>
        <div className="more-info">
          <MoreInfoAccordion film={film} />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const film = this.getFilm();
    const rotationString = this.getRotationString(film.beige_angle);
    const { snackbarOpen } = this.state;

    if (film !== '404') {
      return (
        <React.Fragment>
          <Helmet>
            <title>The Beige Index – {film.title}</title>
          </Helmet>
          <div className="film-info">
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={this.handleClose}
              message={`${film.title} TBI link copied!`}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
            <Paper elevation={3}>
              <div
                className="film-info-grid"
                itemScope={true}
                itemType={'http://schema.org/Movie'}
              >
                <MediaQuery minWidth={1001}>
                  {this.renderDesktop(film, rotationString)}
                </MediaQuery>
                <MediaQuery maxWidth={1000}>
                  {this.renderMobile(film, rotationString)}
                </MediaQuery>
              </div>
            </Paper>
          </div>
          <MediaQuery minWidth={1001}>{this.controls()}</MediaQuery>
        </React.Fragment>
      );
    } else {
      return <ErrorPage />;
    }
  }
}

export default withRouter(FilmInfoRouter);
