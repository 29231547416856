import React from 'react';

export default function ErrorPage() {
  return (
    <div>
      <center>
        <h1>404 Error</h1>
        <h2>
          Uhhhh... How did you get here? We sure don't know. Please leave.
        </h2>
      </center>
    </div>
  );
}
