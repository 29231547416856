import React from 'react';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';

// Import components
import NavBar from './components/navbar';
import App from './App';
import ErrorPage from './pages/errorpage';
import Oscars from './pages/oscars';

import { imdbIDList } from './data/imdbidlist';

// import css
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import TopInfo from './components/topinfo';
import Footer from './components/footer';
import PressList from './components/presslist';

//import package
var packageJson = require('../package.json');

// Select a container for rendering within
const container = document.getElementById('root');
const root = createRoot(container);
const version = packageJson.version;

const getFilm = () => {
  return imdbIDList[Math.floor(Math.random() * 250)];
};

// render within the dom
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <NavBar version={version} />
        <main className="main-container visible-scrollbar">
          <Routes>
            <Route path="/oscars" element={<Oscars />} />
            <Route
              exact
              path="/"
              element={<Navigate to={`/film/${getFilm()}`} replace={true} />}
            ></Route>
            <Route path="/film/*" element={<App />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </main>
        <PressList />
        <Footer version={version} />
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
