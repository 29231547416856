import React from 'react';
import { Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ContactCards from '../components/contactcards';

import './contact.scss';

export default function Contact(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>The Beige Index – About</title>
      </Helmet>
      <Paper elevation={3} className="about-paper-container">
        <div
          className="about-container"
          itemScope={true}
          itemType={'http://schema.org/Article'}
          id="page"
        >
          <div className="header">
            <center>
              <h1 id="header" itemProp={'title'}>
                Contact The Beige Index Team
              </h1>
            </center>
          </div>
          <div>
            <p>
              For media appearances, interviews and partnership inquiries please
              reach out to us at{' '}
              <a href="mailto:info@thebeigeindex.com">info@thebeigeindex.com</a>
              . Also, if you're a fashion brand interested in partnering with
              The Beige Index team to produce quality merch, please do reach out
              and connect with us.
            </p>
            <p>
              You can follow us on socials using the links in the footer below.
            </p>
            <p>
              The Beige Index team does not accept pitches for content. We will
              not knowingly use user-submitted content as our own, and will
              delete any such content sight unseen. By contacting us you agree
              to indemnify us against any claim of ownership, copyright or
              royalties for the use of materials sent to this address.
            </p>

            <ContactCards alan={false} />
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
}
