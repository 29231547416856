import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import './shelf_vert.scss';
import FilmSpineFlexbox from './filmspineflexbox';
import { filmInfo } from '../data/filminfo';

// Polyfill for old browsers to enable .at() calls
function at(n) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += this.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined;
  // Otherwise, this is just normal property access
  return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
  Object.defineProperty(C.prototype, 'at', {
    value: at,
    writable: true,
    enumerable: false,
    configurable: true,
  });
}
// End polyfill

function ShelfVert(props) {
  const { films, selectFilm, selectedField, selectedFilm } = props;
  const [label0, setLabel0] = useState('0');
  const [label1, setLabel1] = useState('1');

  function isNull(film) {
    return film[selectedField] !== null;
  }

  const getLabels = () => {
    const labels = films.filter(isNull);
    const label0 = labels.at(0);
    const label1 = labels.at(-1);

    switch (selectedField) {
      case 'release_year':
      case 'title':
      case 'imdb_user_ranking':
      case 'metascore':
      case 'imdb_user_rating':
      case 'runtime':
      case 'cast_count':
        setLabel0(label0[selectedField]);
        setLabel1(label1[selectedField]);
        break;
      case 'beige_score':
        setLabel0('CREAMY');
        setLabel1('BEIGEWATCH');
        break;
      case 'budget_amount':
        let b0 = filmInfo.find(({ imdb_id }) => imdb_id === label0.imdb_id);
        let b1 = filmInfo.find(({ imdb_id }) => imdb_id === label1.imdb_id);
        setLabel0(b0['budget_string']);
        setLabel1(b1['budget_string']);
        break;
      case 'gross_amount':
        let l0 = filmInfo.find(({ imdb_id }) => imdb_id === label0.imdb_id);
        let l1 = filmInfo.find(({ imdb_id }) => imdb_id === label1.imdb_id);
        setLabel0(l0['gross_string']);
        setLabel1(l1['gross_string']);
        break;
      default:
        setLabel0(label0[selectedField]);
        setLabel1(label1[selectedField]);
        break;
    }
  };

  useEffect(() => {
    getLabels();
  });

  return (
    <React.Fragment>
      <MediaQuery maxWidth={500}>
        <div className="shelf-labels-mob">
          <div id="shelf-label-r">
            <p>{films.at(0)[selectedField]}</p>
          </div>
        </div>
      </MediaQuery>
      <div className="shelf-flexbox-wrapper">
        <div className="shelf-flexbox" id="shelf">
          {films.map((film, i) => {
            if (film.imdb_id === selectedFilm) {
              return (
                <FilmSpineFlexbox
                  data={film}
                  classString={'film-spine-flexbox-wrapper-active'}
                  selectFilm={selectFilm}
                  key={`spine-${i}`}
                />
              );
            } else {
              return (
                <FilmSpineFlexbox
                  data={film}
                  classString={'film-spine-flexbox-wrapper'}
                  selectFilm={selectFilm}
                  key={`spine-${i}`}
                />
              );
            }
          })}
        </div>
      </div>
      <div className="shelf-labels">
        <MediaQuery maxWidth={500}>
          <div id="shelf-label-r">
            <p>{films.at(-1)[selectedField]}</p>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={501}>
          <div id="shelf-label-l">
            <p>{label0}</p>
          </div>
          <div id="shelf-label-r">
            <p>{label1}</p>
          </div>
        </MediaQuery>
      </div>
    </React.Fragment>
  );
}

export default ShelfVert;
