import React from 'react';
import { Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Button } from '@mui/material';

import CastContainer from '../components/castcontainer';
import { supporters } from '../data/supportersdata';
import ContactCards from '../components/contactcards';
import BeigeMeter from '../components/beigemeter';

import './supporters.scss';

export default function Supporters(props) {
  const beige_name = {
    1: 'CREAMY',
    2: 'BEIGE FEVER',
    3: 'MIDDLEBEIGE',
    4: 'DOWN TO BROWN',
    5: 'WELCOME TO BEIGEWATCH',
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>The Beige Index – About</title>
      </Helmet>
      <Paper elevation={3} className="about-paper-container">
        <div
          className="about-container"
          itemScope={true}
          itemType={'http://schema.org/Article'}
          id="page"
        >
          <div className="header">
            <center>
              <h1 id="header" itemProp={'title'}>
                The Beige Index Credited Cast
              </h1>
            </center>
          </div>
          <div>
            <div className="supporters-film-beige">
              <Paper elevation={3} id="meter-paper">
                <div id="meter-wrapper">
                  <BeigeMeter
                    beigeRotation={`${(16 / 7) * 30} 111.63 111.37`}
                  />
                  <center>
                    <h2 id="beige-name">
                      CURRENT SCORE:<br></br>
                      {beige_name[2]}
                    </h2>
                  </center>
                  <div className="cast-container-container">
                    <h4>Cast</h4>
                    <CastContainer cast={supporters} />
                    <div className="cast-button-wrapper">
                      <Button
                        fullWidth
                        variant="contained"
                        color="warning"
                        size="large"
                        href="https://www.patreon.com/thebeigeindex"
                        target="_blank"
                        rel="noreferrer"
                        disableElevation
                      >
                        Join Our Patreon Cast Now
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <p className="first">
              Join us on Patreon as we embark on the grand social experiment
              henceforth known as The Beige Index Credited Cast!
            </p>
            <p>
              With your pledge, your name and your ethnicity/ies will be added
              to our cast list right here, and our patented Beige-o-Meter™ will
              have all it needs to do its dirty, filthy work.
            </p>
            <p>
              Will our Patreons be CREAMY? Will we hit BEIGE FEVER as a team? Do
              we dare to reach for MIDDLEBEIGE? And perish the thought,
              absolutely perish it—could we actually ever achieve DOWN TO BROWN?
              Not in our wildest dreams.
            </p>
            <p>
              And look, even <em>we </em>know we'll never reach the fabled
              WELCOME TO BEIGEWATCH...
            </p>
            <p>
              Not unless all you Nigerian princes out there chip in! Alan tells
              us he's had semi-successful business transactions with one of you
              already. So don't stinge on us, Abayomrunkoje! We need you!
            </p>
            <Button
              className="cast-button"
              fullWidth
              variant="contained"
              disableElevation
              color="warning"
              size="large"
              href="https://www.patreon.com/thebeigeindex"
              target="_blank"
              rel="noreferrer"
            >
              Support The Beige Index: Join Our Patreon Cast Now
            </Button>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
}
