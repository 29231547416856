import React, { Component, Suspense } from 'react';
import CastContainer from '../components/castcontainer';
import BeigeMeter from '../components/beigemeter';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import { ButtonGroup, Tooltip } from '@mui/material';
import MediaQuery from 'react-responsive';
import { withRouter } from '../hooks/withrouter';
import { Helmet } from 'react-helmet-async';

import './oscarsfilm.scss';

class OscarsFilm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beige_name: {
        1: 'CREAMY',
        2: 'BEIGE FEVER',
        3: 'MIDDLEBEIGE',
        4: 'DOWN TO BROWN',
        5: 'WELCOME TO BEIGEWATCH',
      },
      shareText: 'SHARE',
    };
  }

  film = this.props;

  synopsis() {
    return <React.Fragment>{this.props.film.synopsis}</React.Fragment>;
  }

  poster(film) {
    return (
      <img
        itemProp="image"
        src={`https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/posters/poster_${this.props.film.imdb_id}.jpg`}
        width="100%"
        alt={this.props.film.title}
      />
    );
  }

  componentDidUpdate = () => {
    const animateEl = document.getElementById('dial-animation');
    animateEl.beginElement();
  };

  renderDesktop() {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {this.props.film.title}&nbsp;&nbsp;
            </h1>
            <p className="inline-text director-text">
              Dir.{' '}
              <span itemProp={'director'}>{this.props.film.directors}</span>
            </p>
          </span>
        </div>
        <div className="film-poster">{this.poster(this.props.film)}</div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          {this.synopsis()}
        </div>
        <div className="film-beige">
          <BeigeMeter
            beigeScore={this.props.film.beige_score}
            beigeAngle={this.props.film.beige_angle}
            beigeRotation={this.props.film.beige_angle_string}
          />
          <p id="beige-name">
            SCORE: {beige_name[this.props.film.beige_index]}
          </p>
          <div className="cast-wrapper">
            <h5>Cast</h5>
            <CastContainer cast={this.props.film.cast} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMobile() {
    const { beige_name } = this.state;
    return (
      <React.Fragment>
        <div className="film-title">
          <span>
            <h1 id="film-title" itemProp={'name'}>
              {this.props.film.title}&nbsp;&nbsp;
            </h1>
          </span>
        </div>
        <div className="film-poster">{this.poster(this.props.film)}</div>
        <div
          itemScope
          itemType="http://schema.org/Article"
          itemProp="articleBody"
          className="beige-assessment"
        >
          <Suspense>{this.synopsis()}</Suspense>
        </div>
        <div className="film-beige">
          <Paper elevation={0} id="meter-paper">
            <div id="meter-wrapper">
              <BeigeMeter
                beigeScore={this.props.film.beige_score}
                beigeAngle={this.props.film.beige_angle}
                beigeRotation={this.props.film.beige_angle_string}
              />
              <p id="beige-name">SCORE: {beige_name[2]}</p>
            </div>
          </Paper>
        </div>
        <div className="cast-wrapper">
          <h5>Cast</h5>
          <CastContainer cast={this.props.film.cast} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>The Beige Index – {this.props.film.title}</title>
        </Helmet>
        <div className="oscars-film-info">
          <Paper elevation={3} className="lighten-background">
            <div
              className="whitewash-info-grid"
              itemScope={true}
              itemType={'http://schema.org/Movie'}
            >
              <MediaQuery minWidth={1001}>{this.renderDesktop()}</MediaQuery>
              <MediaQuery maxWidth={1000}>{this.renderMobile()}</MediaQuery>
            </div>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(OscarsFilm);
