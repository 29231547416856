/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "tbiemailform",
            "endpoint": "https://gj267ffzph.execute-api.us-west-1.amazonaws.com/staging",
            "region": "us-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gwys6akx4fdurdq5awdn7oux3m.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uxtaqz27kzeuzl5cd4noqyvs4y",
    "aws_cognito_identity_pool_id": "us-west-1:6a2a6831-5b7e-4eac-9a22-c7751c12d10a",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_TQ94GQXQk",
    "aws_user_pools_web_client_id": "4ot3br481182554qe4l6v4r82r",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
