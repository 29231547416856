import React, { Component } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

import './tableview.scss';

class TableView extends Component {
  state = {
    columns: [
      { field: 'imdb_user_ranking', headerName: 'User Ranking', flex: 1 },
      {
        field: 'title',
        headerName: 'Film',
        flex: 2,
      },
      { field: 'release_year', headerName: 'Release Year', flex: 1 },
      {
        field: 'beige_score',
        headerName: 'Beige Score',
        flex: 1,
      },
      { field: 'imdb_user_rating', headerName: 'User Rating', flex: 1 },
      { field: 'metascore', headerName: 'Metacritic (%)', flex: 1 },
      { field: 'budget_amount', headerName: 'Budget (USD)', flex: 1 },
      { field: 'gross_amount', headerName: 'Revenue (USD)', flex: 1 },
      { field: 'runtime', headerName: 'Runtime (mins)', flex: 1 },
      { field: 'cast_count', headerName: 'Cast Size', flex: 1 },
    ],
  };

  render() {
    const { rows } = this.props;
    const { columns } = this.state;

    return (
      <Paper className="table-container">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[25]}
          getRowId={(row) => row.imdb_id}
          isRowSelectable={(row) => row.imdb_user_ranking > 300}
          onRowClick={(params) => {
            window.history.pushState({}, undefined, `/film/${rows.imdb_id}`);
            this.props.selectFilm(params.id);
          }}
          GridLinesVisibility="None"
          sx={{
            color: 'rgba(245, 245, 245,1)',
            borderColor: 'rgba(245, 245, 245,0.5)',
            borderWidth: '2px',
            fontFamily: 'Poppins',
            fontWeight: '500',
            // borderBottomColor: 'rgba(245, 245, 245, 0.25)',
          }}
        />
      </Paper>
    );
  }
}

export default TableView;
