import React from 'react';
import { Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Button } from '@mui/material';

import CastContainer from '../components/castcontainer';
import { supporters } from '../data/supportersdata';
import ContactCards from '../components/contactcards';
import BeigeMeter from '../components/beigemeter';

import './oscars.scss';
import FilmInfoRouter from './filminfo_router';
import TheWhitewash from './thewhitewash';
import OscarsFilm from './oscarsfilm';
import { oscarsData } from '../data/oscarsdata';
import Oscarsfilm from './oscarsfilm';
import ThewhitewashOscars from './thewhitewash-oscars';

export default function Oscars(props) {
  const beige_name = {
    1: 'CREAMY',
    2: 'BEIGE FEVER',
    3: 'MIDDLEBEIGE',
    4: 'DOWN TO BROWN',
    5: 'WELCOME TO BEIGEWATCH',
  };

  const renderOscars = (oscarsData) => {
    return oscarsData.map((film) => {
      <OscarsFilm film={film} />;
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>The Beige Index – Oscars 2023</title>
      </Helmet>
      <Paper elevation={3} className="oscars-paper-container">
        <div
          className="oscars-container"
          itemScope={true}
          itemType={'http://schema.org/Article'}
          id="page"
        >
          <div className="header">
            <center>
              <img
                src={
                  'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/TBI-oscars-logo.png'
                }
                alt="The Beige Index – Oscars"
                className="oscars-logo"
                width={'50%'}
              />
              <br></br>
              <h1 id="header" itemProp={'title'}>
                How diverse are the 2023 Best Picture nominees?
              </h1>
            </center>
            <br></br>
            <div className="intro-text beige-assessment">
              <p>
                Overall, it's a pretty beige year. The overall score for the
                2023 best picture casts is Beige Fever, with an overall beige
                score of 15.04 (where 12 is the lowest, due to methodological{' '}
                <a href="/film/about">reasons</a>). This is skewed, of course,
                by the large casts of small roles in films like <em>Elvis</em>{' '}
                and <em>Triangle of Sadness</em>. These films have a lot of
                beige, but they would not be considered beige films, per se.
              </p>
              <p>
                On the other hand, you have the distinctly beige film{' '}
                <em>Everything Everywhere All at Once</em>, whose cast is
                actually 44% white—making the film seem, in absolute terms, much
                whiter than it actually is.
              </p>
              <p>
                Overall, 364/538 (68%) of credited 2023 Best Picture castmembers
                are white.
              </p>
              <p>
                Compare this to the IMDb Top 250 films, where 64% of castmembers
                are white, and you'll see that the Oscars this year are more
                white than the already very creamy IMDb Top 250. This may have
                to do with three of this year's ten nominees,{' '}
                <em>All Quiet on the Western Front</em>,{' '}
                <em>The Banshees of Inisherin</em> and <em>Women Talking</em>,
                all historical in nature, are 100% white. And perhaps this
                surprises no one: the Oscars remains, after all, perhaps the
                whitest cultural institution of any relevance.
              </p>
              <p>
                But let's put serious statistics aside and get to the fun part.
                Will the beige-o-meter declare your favourite films of 2022
                #Creamy, #BeigeFever, #Middlebeige, #DowntoBrown, or
                #WelcometoBeigewatch? Who knows? Oh wait, <em>we</em> know.
                That's, like, our whole thing.
              </p>
              <p>Scroll down for more.</p>
              <hr></hr>
              <p>
                <b>The Beige Index</b> is the Bechdel test for race, measuring
                the level of ethnic diversity in popular films. You may be
                familiar with{' '}
                <a href="/">our coverage of the IMDb Top 250 films</a>, where we
                ranked each of those films on our beige-o-meter™, and yes, we
                watched <em>every</em> film, all 500+ hours.
              </p>
            </div>
          </div>
          {oscarsData.map((film) => {
            return <Oscarsfilm film={film} />;
          })}
          <div>
            <p>
              <center>
                For more information, please contact The Beige Index team at{' '}
                <a href="mailto://info@thebeigeindex.com">
                  info@thebeigeindex.com
                </a>
                .
              </center>
            </p>
            <p>
              <center>
                And if you want more beige-o-meter action you can check out our
                coverage of{' '}
                <a href="https://thebeigeindex.com">the IMDb Top 250 films</a>.
              </center>
            </p>
            <br></br>
          </div>
          <ThewhitewashOscars />
        </div>
      </Paper>
    </React.Fragment>
  );
}
